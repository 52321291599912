import { Chip, useMediaQuery } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/styles'
import makeStyles from '@mui/styles/makeStyles'
import { clsx } from 'clsx'
import GameTypeIcon from 'Components/_common/GameTypeIcon'
import ConditionalWrap from 'conditional-wrap'
import { useCategoryName, usePlayersCountName } from 'Queries/Dictionaries'
import React from 'react'
import { Else, If, Then, When } from 'react-if'
import { Link } from 'react-router-dom'
import Avatar from 'Theme/Avatar'
import Box from 'Theme/Box'
import Card, { CardContent } from 'Theme/Card'

import { PROFILE_IMAGE_TYPE } from '../../../Queries/utils'
import { navigateToContestPage } from '../../../Scenes/navigators'
import { BREAKPOINT_MIN_MD } from '../../../Theme/stylesUtil'
import { transformTextWithThreeDots } from '../../../utils/text-transformation-utils'
import { userImageSelector } from '../../../utils/user-images-utils'
import Timer from '../../_common/Timer'
import { RestoreContest } from '../ArchiveComponents/RestoreContest'
import ContestStatesStep from '../ContestStatesStep'
import { contestStepsForMobile, getContestStepperState } from '../utils'
import InfoCard from './InfoCard'

const useStyles = makeStyles((theme) => ({
  background: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: theme.shape.borderRadius * 2,
    top: 0,
    left: 0,
  },
  backgroundConteiner: {
    background:
      'linear-gradient(270deg, rgba(32, 33, 38, 0.2) 0%, rgba(32, 33, 38, 0.6446) 44.01%, rgba(32, 33, 38, 0.92) 100%)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: theme.shape.borderRadius * 2,
    zIndex: 1,
  },
  active: {
    cursor: 'pointer',
    transition: theme.transitions.create(['transform', 'box-shadow']),

    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: theme.shadows[6],
    },
  },
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    position: 'relative',
    cursor: 'pointer',
    borderRadius: theme.shape.borderRadius * 3,
    boxShadow: theme.shadows[1],
  },
  container: {
    display: 'flex',
    height: '100%',
    padding: theme.spacing(3),
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    height: '100%',
  },
}))

const ContestCard = ({ event, disableStepper = false }) => {
  const classes = useStyles()
  const theme = useTheme()

  const categoryName = useCategoryName(event.category_id)
  const playersCountName = usePlayersCountName(event.players_count_id)
  const readablePrize = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(event.prize_value)

  const link = navigateToContestPage(event.id, true)
  const isBiggerThanMDScreen = useMediaQuery(BREAKPOINT_MIN_MD)

  return (
    <Card className={clsx(classes.root, event.id && classes.active)}>
      <ConditionalWrap
        condition={!!link}
        wrap={(children) => (
          <Link
            draggable={false}
            className={classes.link}
            to={link}
          >
            {children}
          </Link>
        )}
      >
        <If condition={isBiggerThanMDScreen}>
          <Then>
            <CardContent className={classes.container}>
              <Box
                position="relative"
                flex={1}
                display="flex"
                flexDirection={'row'}
                width={'calc(100% - 210px)'}
              >
                <Box>
                  <Box className={classes.backgroundConteiner} />
                  <img
                    loading="lazy"
                    className={classes.background}
                    alt="Background"
                    src={event.contest_images?.[event.contest_images.length - 1].urls.large}
                  />
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                  alignSelf="flex-end"
                  zIndex={2}
                  position="relative"
                  paddingBottom={3}
                  paddingTop={3}
                  px={3}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                    gap={1}
                    paddingTop={1}
                    width={'calc(100% - 95px)'}
                  >
                    <Typography
                      variant="h2"
                      color="text.primary"
                      sx={{ wordWrap: 'break-word' }}
                    >
                      {transformTextWithThreeDots(event.title, 30)}
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                      gap={1}
                      paddingTop={1}
                    >
                      <When condition={!!categoryName}>
                        <GameTypeIcon
                          gameType={categoryName.toLowerCase()}
                          size="small"
                          color={theme.palette.text.tertiary}
                        />
                      </When>

                      <When condition={playersCountName}>
                        <Typography
                          variant="caption"
                          color="text.tertiary"
                        >
                          {playersCountName || ''}
                        </Typography>
                      </When>

                      <Typography
                        variant="body2bold"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        color="text.tertiary"
                      >
                        by {transformTextWithThreeDots(event.owner.login)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    gap={1}
                    alignItems="flex-end"
                  >
                    <InfoCard
                      title="Prize pool"
                      value={
                        <Chip
                          label={
                            <Typography
                              variant="body2bold"
                              color="text.primary"
                            >
                              {readablePrize}
                            </Typography>
                          }
                          color="secondary"
                          component="strong"
                          size="small"
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
              >
                {!disableStepper && <ContestStatesStep contest={event} />}
                {event.is_soft_deleted && <RestoreContest contestId={event.id} />}
              </Box>
            </CardContent>
          </Then>
          <Else>
            <Box
              sx={{
                width: '100%',
                height: '200px',
                background: `linear-gradient(270deg,
                  rgba(32, 33, 38, 0.2) 0%,
                  rgba(32, 33, 38, 0.6446) 44.01%,
                  rgba(32, 33, 38, 0.92) 100%
                ),
                url(${event.contest_images?.[event.contest_images.length - 1].urls.medium})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '12px',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Box
                    height="12px"
                    width="12px"
                    borderRadius="50%"
                    backgroundColor="primary.main"
                    position="relative"
                    top="2px"
                  />
                  <Box display="grid">
                    <Typography
                      color="text.secondary"
                      variant="h6"
                    >
                      {contestStepsForMobile[getContestStepperState(event.state)]?.name}
                    </Typography>
                    <Typography
                      color="text.tertiary"
                      variant="caption"
                    >
                      {contestStepsForMobile[getContestStepperState(event.state)].stepStartDate(event)}
                    </Typography>
                    {getContestStepperState(event.state) === 0 && <Timer seconds={event.time_add_maps_countdown_sec} />}
                  </Box>
                </Box>
                <Chip
                  label={
                    <Typography
                      variant="body2bold"
                      color="text.primary"
                    >
                      {readablePrize}
                    </Typography>
                  }
                  color="secondary"
                  component="strong"
                  size="small"
                />
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Avatar
                    size="small"
                    src={userImageSelector(event.owner?.user_images, PROFILE_IMAGE_TYPE.AVATAR, 'medium')}
                  />
                  <Box sx={{ height: '32px', display: 'grid' }}>
                    <Typography
                      variant="h6"
                      color="text.primary"
                    >
                      {transformTextWithThreeDots(event.title, 15)}
                    </Typography>
                    <Typography
                      variant="body2bold"
                      color="text.tertiary"
                      lineHeight="16px"
                    >
                      {`by ${transformTextWithThreeDots(event.owner.login)}`}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex">
                  <Typography
                    variant="caption"
                    color="text.tertiary"
                  >
                    {playersCountName || ''}
                  </Typography>
                  <GameTypeIcon
                    gameType={categoryName.toLowerCase()}
                    size="small"
                    color={theme.palette.text.tertiary}
                  />
                </Box>
              </Box>
            </Box>
          </Else>
        </If>
      </ConditionalWrap>
    </Card>
  )
}

export default ContestCard
