import { useAuth } from 'Components/Auth'
import { stringify } from 'query-string'
import { useMemo } from 'react'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'

import {
  CONTEST_LIFE_STATES,
  getIsCommunityVoteRound,
  getIsPublishedRound,
  getIsTiebreakersRound,
} from '../Components/ContestComponents/utils'
import { PAYMENTS_STATES } from '../utils/payments-utils'
import QUERY, {
  makeContestAnnouncementApiPath,
  makeContestApiPath,
  makeContestArchiveApiPath,
  makeContestCommentsPath,
  makeContestCommunityVotePath,
  makeContestInvitationUserIdApiPath,
  makeContestJudgeBookmarkingStatusApiPath,
  makeContestJudgesStatusApiPath,
  makeContestMapsApiPath,
  makeContestPaymentApiPath,
  makeContestPublishApiPath,
  makeContestQualificationApiPath,
  makeContestQualificationNextApiPath,
  makeContestRestoreApiPath,
  makeContestResultApiPath,
  makeContestsApiPath,
  makeContestSingleMapApiPath,
  makeContestSubmissionDeadlinesApiPath,
  makeContestSubmissionMapCandidatesApiPath,
  makeMapQualificationCheckListApiPath,
  makeQualificationAcceptMapApiPath,
  makeQualificationDiscardMapApiPath,
  makeVoteRoundApiPath,
  makeVoteRoundCacheApiPath,
  makeVoteRoundCacheMapApiPath,
  makeVoteRoundNextRoundApiPath,
} from './constants'
import { getCategoryFromRelations, getMapFromRelations, getUserFromRelations } from './utils'

const { SUBMISSION, QUALIFICATION, EVALUATION, FINAL } = CONTEST_LIFE_STATES

export const useContestJudges = (contest) =>
  useMemo(
    () =>
      contest.judges && contest.relations
        ? contest.judges.map((judge) => ({
            ...contest.relations.users[judge.user_id],
            ...judge,
            ...contest.relations.contest_judges[judge.judge_id],
            judge_favorite_maps:
              contest.relations.contest_judges[judge.judge_id]?.judge_favorite_maps?.map(({ map_id }) => map_id) || [],
            judge_original_maps:
              contest.relations.contest_judges[judge.judge_id]?.judge_original_maps?.map(({ map_id }) => map_id) || [],
          }))
        : [],
    [contest],
  )

export const useContestBackground = (contest) =>
  useMemo(() => contest?.contest_images?.[contest.contest_images.length - 1].urls.large || '', [contest])

export const useContestsQuery = (params, queryKey = '') => {
  const { axios } = useAuth()
  const CONTESTS_URL = makeContestsApiPath()
  return useInfiniteQuery(
    [CONTESTS_URL, queryKey],
    async ({ pageParam = 1 }) => {
      const queryObject = {
        _page: pageParam,
        // TODO limit not working properly
        _limit: 999,
        // _limit: pageParam === 1 ? 8 : 24,
        _sort: '-time_begin',
        is_hidden: false,
        ...params,
      }

      const queryString = stringify(queryObject, { skipNull: true })

      const { data } = await axios.get(`${CONTESTS_URL}?${queryString}`)

      return {
        data: data.data.map((item) => ({ ...item, owner: getUserFromRelations(data, item.owner_id) })),
        total: data.total,
        contests: data.relations.contests,
      }
    },
    {
      retry: false,
      cacheTime: 10000,
      retryDelay: 2000,
      staleTime: 5000,
    },
  )
}

export const useSoftDeletetMutation = (contestId) => {
  const { axios } = useAuth()

  return useMutation(() => axios.post(makeContestArchiveApiPath(contestId), {}))
}

export const useRestoreContestMutation = (contestId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const CONTESTS_URL = makeContestsApiPath()
  const CONTEST_URL = makeContestApiPath(contestId)

  return useMutation((payload) => axios.post(makeContestRestoreApiPath(contestId), payload), {
    onSettled: () => {
      queryClient.invalidateQueries([CONTESTS_URL, 'archive'])
      queryClient.invalidateQueries([CONTEST_URL])
    },
    enabled: !!contestId,
  })
}

export const useContestQuery = (contestId, options = {}) => {
  const { axios } = useAuth()
  const CONTEST_URL = makeContestApiPath(contestId)

  return useQuery(
    [CONTEST_URL],
    async () => {
      const { data } = await axios.get(CONTEST_URL)

      return data
    },
    {
      ...options,
      enabled: !!contestId,
    },
  )
}

export const useSaveContestMutation = (contestId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const CONTEST_URL = makeContestApiPath(contestId)

  // TODO urls composition below should be refactored
  return useMutation(
    (payload) => axios[contestId ? 'put' : 'post'](`${QUERY.CONTESTS}${contestId ? `/${contestId}` : ''}`, payload),
    {
      onSettled: () => {
        queryClient.invalidateQueries(CONTEST_URL)
      },
      enabled: !!contestId,
    },
  )
}

export const useContestSubmissionDeadlinesQuery = () => {
  const { axios } = useAuth()
  const URL = makeContestSubmissionDeadlinesApiPath()

  return useQuery(
    [URL],
    async () => {
      const { data } = await axios.get(URL)

      return data.data
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    },
  )
}

export const useAddAnnouncementMutation = (contestId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const CONTEST_URL = makeContestApiPath(contestId)

  // TODO urls composition below should be refactored
  return useMutation((payload) => axios.post(`${QUERY.CONTESTS}/${contestId}${QUERY.ANNOUNCEMENTS}`, payload), {
    onSettled: () => {
      queryClient.invalidateQueries(CONTEST_URL)
    },
    enabled: !!contestId,
  })
}

export const useEditAnnouncementMutation = (contestId, id) => {
  const { axios } = useAuth()
  return useMutation((payload) => axios.put(makeContestAnnouncementApiPath(contestId, id), payload))
}

export const useDeleteAnnouncementMutation = (contestId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const CONTEST_URL = makeContestApiPath(contestId)

  return useMutation(({ contestId, id }) => axios.delete(makeContestAnnouncementApiPath(contestId, id)), {
    onSettled: () => {
      queryClient.invalidateQueries(CONTEST_URL)
    },
  })
}

// NOTE - should not be used in production right now
export const useDeleteContestMutation = () => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const CONTESTS_URL = makeContestsApiPath()

  return useMutation((contestId) => axios.delete(makeContestApiPath(contestId)), {
    onSettled: () => {
      queryClient.invalidateQueries(CONTESTS_URL)
    },
  })
}

export const useSaveJudgeMutation = (contestId, disableIvalidate = false) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const CONTEST_URL = makeContestApiPath(contestId)

  // TODO urls composition below should be refactored
  return useMutation(({ payload }) => axios.post(`${QUERY.CONTESTS}/${contestId}${QUERY.JUDGES}`, payload), {
    onSuccess: () => {
      !disableIvalidate && queryClient.invalidateQueries(CONTEST_URL)
    },
    enabled: !!contestId,
  })
}

export const useSortJudgeMutation = (contestId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const CONTEST_URL = makeContestApiPath(contestId)

  return useMutation((payload) => axios.put(`${QUERY.CONTESTS}/${contestId}`, payload), {
    onSuccess: () => {
      queryClient.invalidateQueries(CONTEST_URL)
    },
    enabled: !!contestId,
  })
}

export const useDeleteJudgeMutation = (contestId, disableIvalidate = false) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const CONTEST_URL = makeContestApiPath(contestId)

  // TODO urls composition below should be refactored
  return useMutation(
    ({ contestId, judgeId }) => axios.delete(`${QUERY.CONTESTS}/${contestId}${QUERY.JUDGES}/${judgeId}`),
    {
      onSuccess: () => {
        !disableIvalidate && queryClient.invalidateQueries(CONTEST_URL)
      },
      enabled: !!contestId,
    },
  )
}

export const useDeleteFaqMutation = (contestId) => {
  const { axios } = useAuth()

  // TODO urls composition below should be refactored
  return useMutation((faqId) => axios.delete(`${QUERY.CONTESTS}/${contestId}${QUERY.FAQ}/${faqId}`))
}

export const useUploadContestImageMutation = (contestId) => {
  const { axios } = useAuth()

  // TODO urls composition below should be refactored
  return useMutation(
    async ({ file }) => {
      let formData = new FormData()

      formData.append('file', file)

      const { data } = await axios.post(`${QUERY.CONTESTS}/${contestId}${QUERY.IMAGES}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      return data
    },
    {
      enabled: !!contestId,
    },
  )
}

export const useLikeContestMutation = () => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()

  // TODO urls composition below should be refactored
  return useMutation((id) => axios.post(`${QUERY.CONTESTS}/${id}${QUERY.LIKES}`), {
    onMutate: async (contestId) => {
      await queryClient.cancelQueries([`${QUERY.CONTESTS}/${contestId}`])

      const oldContest = queryClient.getQueryData([`${QUERY.CONTESTS}/${contestId}`])
      const newContest = {
        ...oldContest,
        is_like: true,
        likes_total: +oldContest.likes_total + 1,
      }

      queryClient.setQueryData([`${QUERY.CONTESTS}/${contestId}`], newContest)
      return newContest
    },
    onError: (_err, _newTodo, context) => {
      queryClient.setQueryData(
        [`${QUERY.CONTESTS}/${context.oldContest.id}`, `${context.oldContest.id}`],
        context.oldContest,
      )
    },
    onSettled: ({ data }) => {
      queryClient.invalidateQueries([`${QUERY.CONTESTS}/${data[0].collection_id}`, `${data[0].collection_id}`])
    },
  })
}

export const useUnlikeContestMutation = () => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()

  // TODO urls composition below should be refactored
  return useMutation((id) => axios.delete(`${QUERY.CONTESTS}/${id}${QUERY.LIKES}`), {
    onMutate: async (contestId) => {
      await queryClient.cancelQueries([`${QUERY.CONTESTS}/${contestId}`])

      const oldContest = queryClient.getQueryData([`${QUERY.CONTESTS}/${contestId}`])
      const newContest = {
        ...oldContest,
        is_like: false,
        likes_total: +oldContest.likes_total - 1,
      }

      queryClient.setQueryData([`${QUERY.CONTESTS}/${contestId}`], newContest)
      return newContest
    },
    onError: (_err, _newTodo, context) => {
      queryClient.setQueryData(
        [`${QUERY.CONTESTS}/${context.oldContest.id}`, `${context.oldContest.id}`],
        context.oldContest,
      )
    },
    onSettled: (_data, _error, _variables, context) => {
      queryClient.invalidateQueries([`${QUERY.CONTESTS}/${context.oldContest.id}`, `${context.oldContest.id}`])
    },
  })
}

export const useAddContestCommentMutation = (contestId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const path = makeContestCommentsPath(contestId)

  return useMutation((payload) => axios.post(path, payload), {
    onSettled: () => {
      queryClient.invalidateQueries([path])
    },
    enabled: !!contestId,
  })
}

export const useAddContestReplyMutation = (contestId, commentId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const path = makeContestCommentsPath(contestId)

  return useMutation((payload) => axios.post(`${path}/${commentId}`, payload), {
    onSettled: () => {
      queryClient.invalidateQueries([path])
    },
    enabled: !!contestId,
  })
}

export const useEditContestCommentMutation = (contestId, commentId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const path = makeContestCommentsPath(contestId)

  return useMutation((payload) => axios.put(`${path}/${commentId}`, payload), {
    onSettled: () => {
      queryClient.invalidateQueries([path])
    },
    enabled: !!contestId,
  })
}

export const useDeleteContestCommentMutation = (contestId, commentId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const path = makeContestCommentsPath(contestId)

  return useMutation(() => axios.delete(`${path}/${commentId}`), {
    onSettled: () => {
      queryClient.invalidateQueries([path])
    },
    enabled: !!contestId,
  })
}

export const useReactOnContestCommentMutation = (id, commentId, contentType) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const path = makeContestCommentsPath(id, contentType)

  return useMutation(({ type }) => axios.post(`${path}/${commentId}/likes/${type}`), {
    onSuccess: (_) => {
      queryClient.invalidateQueries([path])
    },
    enabled: !!id,
  })
}

export const useDeleteContestCommentReactionMutation = (id, commentId, contentType) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const path = makeContestCommentsPath(id, contentType)

  return useMutation(({ type }) => axios.delete(`${path}/${commentId}/likes/${type}`), {
    onSettled: () => {
      queryClient.invalidateQueries([path])
    },
    enabled: !!id,
  })
}

export const useContestSubmitMapCandidatesQuery = (contestId) => {
  const { axios } = useAuth()
  const CONTEST_CANDIDATES_URL = makeContestSubmissionMapCandidatesApiPath(contestId)
  const params = { _limit: 999 }

  return useQuery(
    [CONTEST_CANDIDATES_URL],
    async () => {
      const { data } = await axios.get(CONTEST_CANDIDATES_URL, { params })

      return data.data
    },
    {
      enabled: !!contestId,
    },
  )
}

export const useContestSubmitMapMutation = (contestId) => {
  const { axios, id } = useAuth()
  const modalContestParam = `?user_id=${id}`
  const queryClient = useQueryClient()
  const CONTEST_MAPS_URL = makeContestMapsApiPath(contestId)
  const CONTEST_CANDIDATES_URL = makeContestSubmissionMapCandidatesApiPath(contestId)
  const URL = makeContestMapsApiPath(contestId)
  const CONTEST_SUBMISSIONS_MAPS_URL = `${makeContestMapsApiPath(contestId)}${modalContestParam}`

  return useMutation(({ payload }) => axios.post(URL, payload), {
    onSettled: () => {
      queryClient.invalidateQueries(CONTEST_MAPS_URL)
      queryClient.invalidateQueries(CONTEST_CANDIDATES_URL)
      queryClient.invalidateQueries(CONTEST_SUBMISSIONS_MAPS_URL)
    },
    enabled: !!contestId,
  })
}

const getContestMapsStateQueryParam = (state) => `contest_maps.state=${state}`

export const useContestRoundMapListQuery = (contestId, state, isFromModal) => {
  const { axios, id } = useAuth()
  // TODO contest_maps.state=submission bugged, instead use get without params
  const contestParam = state === SUBMISSION ? '' : `?${getContestMapsStateQueryParam(state)}`
  const modalContestParam = `?user_id=${id}`
  const URL = `${makeContestMapsApiPath(contestId)}${isFromModal ? modalContestParam : contestParam}`
  const params = { _limit: 999, _sort: 'title' }

  return useQuery(
    [URL],
    async () => {
      if (isFromModal && !id) return
      const { data } = await axios.get(URL, { params })
      return {
        ...data,
        data: data.data.map((map) => ({
          ...map,
          user: getUserFromRelations(data, map.user_id),
          categoryName: map.categories ? getCategoryFromRelations(data, map.categories[0].category_id).name : '',
        })),
      }
    },
    {
      enabled: !!contestId && !!state,
    },
  )
}

export const MAPS_UNCHECKED = 'Unchecked'
export const MAPS_ACCEPTED = 'Accepted'
export const MAPS_DISCARDED = 'Discarded'
export const MAP_STATE_TYPES = [MAPS_UNCHECKED, MAPS_ACCEPTED, MAPS_DISCARDED]

const DISCARDED_QUERY = 'contest_maps.discarded=true'
const NOT_DISCARDED_QUERY = 'contest_maps.discarded=false'

const getQualificationUrlParams = (type) => {
  if (type === MAPS_ACCEPTED) return `?${getContestMapsStateQueryParam(EVALUATION)}`

  if (type === MAPS_DISCARDED) return `?${getContestMapsStateQueryParam(QUALIFICATION)}&${DISCARDED_QUERY}`

  return `?${getContestMapsStateQueryParam(QUALIFICATION)}&${NOT_DISCARDED_QUERY}`
}

const getQualificationMapApiUrlByType = (contestId, type) =>
  `${makeContestMapsApiPath(contestId)}${getQualificationUrlParams(type)}`

const getVoteRoundNotDiscardedMapsApiUrl = (contestId, contestState) =>
  `${makeContestMapsApiPath(contestId)}?${getContestMapsStateQueryParam(contestState)}&${NOT_DISCARDED_QUERY}`

export const useContestQualificationMapsQuery = (contestId, type) => {
  const { axios } = useAuth()
  const URL = getQualificationMapApiUrlByType(contestId, type)
  const params = {
    _limit: 999,
  }

  return useQuery(
    [URL],
    async () => {
      const { data } = await axios.get(URL, { params })

      return data
    },
    {
      enabled: !!contestId && !!type,
    },
  )
}

export const useContestQualificationSingleMapQuery = (contestId, mapId) => {
  const { axios } = useAuth()
  const URL = makeContestSingleMapApiPath(contestId, mapId)

  return useQuery(
    [URL],
    async () => {
      const { data } = await axios.get(URL)

      return data
    },
    {
      enabled: !!contestId && !!mapId,
    },
  )
}

export const useContestQualificationStartMutation = (contestId, mapId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const URL = makeContestQualificationApiPath(contestId)

  return useMutation(
    ({ ids = [], type = '' } = {}) => axios.post(URL, { map_id: mapId, contest_qualification_checklist_ids: ids }),
    {
      enabled: !!contestId && !!mapId,
      onSuccess: (_, variables = {}) => {
        const { type } = variables

        if (type === MAPS_ACCEPTED)
          queryClient.invalidateQueries(getQualificationMapApiUrlByType(contestId, MAPS_ACCEPTED))

        if (type === MAPS_DISCARDED)
          queryClient.invalidateQueries(getQualificationMapApiUrlByType(contestId, MAPS_DISCARDED))

        if (!!type) queryClient.invalidateQueries(getQualificationMapApiUrlByType(contestId, MAPS_UNCHECKED))

        queryClient.invalidateQueries(makeContestSingleMapApiPath(contestId, mapId))
      },
    },
  )
}

export const useContestQualificationChecklistQuery = (contestId) => {
  const { axios } = useAuth()
  const URL = makeMapQualificationCheckListApiPath(contestId)

  return useQuery(
    [URL],
    async () => {
      const { data } = await axios.get(URL)

      return data.data
    },
    {
      enabled: !!contestId,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )
}

export const useContestQualificationDiscardMapMutation = (contestId, mapId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const URL = makeQualificationDiscardMapApiPath(contestId, mapId)

  return useMutation(() => axios.post(URL), {
    onSuccess: () => {
      queryClient.invalidateQueries(getQualificationMapApiUrlByType(contestId, MAPS_UNCHECKED))
      queryClient.invalidateQueries(getQualificationMapApiUrlByType(contestId, MAPS_DISCARDED))
    },
  })
}

export const useContestQualificationAcceptMapMutation = (contestId, mapId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const URL = makeQualificationAcceptMapApiPath(contestId, mapId)

  return useMutation(() => axios.post(URL), {
    onSuccess: () => {
      queryClient.invalidateQueries(getQualificationMapApiUrlByType(contestId, MAPS_UNCHECKED))
      queryClient.invalidateQueries(getQualificationMapApiUrlByType(contestId, MAPS_ACCEPTED))
    },
  })
}

export const useContestQualificationNextRoundMutation = (contestId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const URL = makeContestQualificationNextApiPath(contestId)
  const CONTEST_URL = makeContestApiPath(contestId)

  return useMutation(() => axios.post(URL), {
    onSuccess: () => {
      queryClient.invalidateQueries(CONTEST_URL)
    },
  })
}

export const getIsCommunityVoteConfirmed = (data, userId) => !!data?.users_voted.find((id) => id === userId)

export const useContestVoteRoundQuery = (contestId, contestState) => {
  const { axios, id: userId } = useAuth()
  const URL = makeVoteRoundApiPath(contestId, contestState)

  return useQuery(
    [URL, userId],
    async () => {
      const { data } = await axios.get(URL)
      const isCommunityVote = getIsCommunityVoteRound(contestState)
      const isTiebreakerVote = getIsTiebreakersRound(contestState)
      // NOTE tiebreaker round return array of object with debatable maps, take one until new requirements
      const resolvedData = (isTiebreakerVote && data.data[0]) || data
      let isVoteConfirmed, isVoteFinished

      if (isCommunityVote) {
        isVoteConfirmed = getIsCommunityVoteConfirmed(resolvedData, userId)
        isVoteFinished = false
      } else {
        isVoteConfirmed = isTiebreakerVote
          ? resolvedData?.voted_user_ids?.some((id) => id === userId)
          : !resolvedData?.in_progress?.some((u) => u.user_id === userId)
        isVoteFinished = isTiebreakerVote ? false : !resolvedData?.in_progress?.length
      }

      return { ...resolvedData, isVoteConfirmed, isVoteFinished }
    },
    {
      enabled: !!contestId,
    },
  )
}

export const useContestVoteRoundMapsQuery = (contestId, contestState) => {
  const { axios } = useAuth()
  const URL = getVoteRoundNotDiscardedMapsApiUrl(contestId, contestState)
  const params = {
    _limit: 999,
  }

  return useQuery(
    [URL],
    async () => {
      const { data } = await axios.get(URL, { params })

      return data?.data
    },
    {
      enabled: !!contestId,
    },
  )
}

export const useContestVoteRoundCacheMapsQuery = (contestId, contestState) => {
  const isPublishedRound = getIsPublishedRound(contestState)
  const { axios, id: userId } = useAuth()
  const URL = makeVoteRoundCacheApiPath(contestId, contestState)
  const params = {
    _limit: 999,
  }

  return useQuery(
    [URL, userId],
    async () => {
      const { data } = await axios.get(URL, { params })

      return data
    },
    {
      enabled: !!contestId && !!contestState && !isPublishedRound,
    },
  )
}

const filterVoteCacheMaps = (list = [], cacheList = []) => {
  const result = {
    unchecked: [],
    accepted: [],
    discarded: [],
  }
  if (!Array.isArray(list)) return result
  list.forEach((i) => {
    const index = cacheList.findIndex((c) => c.map_id === i.id)
    const item = cacheList[index]
    if (item?.discarded) {
      result.discarded.push(i)
    } else if (item) {
      result.accepted[index] = i
    } else {
      result.unchecked.push(i)
    }
  })
  // Filter gaps in an array, when there are discarded items
  result.accepted = result.accepted.filter((i) => !!i)
  return result
}

export const useContestRoundCache = (contestId, contestState) => {
  const isTiebreakersRound = getIsTiebreakersRound(contestState)
  const isCommunityVoteRound = getIsCommunityVoteRound(contestState)
  const resolvedStateForMaps = ((isCommunityVoteRound || isTiebreakersRound) && FINAL) || contestState
  const {
    data: allMaps = [],
    isFetching: allMapsIsFetching,
    isFetched: isAllFetched,
  } = useContestVoteRoundMapsQuery(contestId, resolvedStateForMaps)
  const {
    data: cacheData = [],
    isFetching: cacheDataIsFetching,
    isFetched: isCacheFetched,
  } = useContestVoteRoundCacheMapsQuery(contestId, contestState)
  const { data: roundData } = useContestVoteRoundQuery(isTiebreakersRound && contestId, contestState)
  const resolvedAllMaps = isTiebreakersRound ? allMaps.filter((map) => roundData?.map_ids.includes(map.id)) : allMaps

  return {
    ...useMemo(() => filterVoteCacheMaps(resolvedAllMaps, cacheData), [resolvedAllMaps, cacheData]),
    isFetching: allMapsIsFetching || cacheDataIsFetching,
    isFetched: isAllFetched && isCacheFetched,
  }
}

export const useContestVoteRoundCacheMapMutation = (contestId, contestState) => {
  const { axios, id: userId } = useAuth()
  const queryClient = useQueryClient()

  return useMutation(
    ({ type = 'post', mapId, submitted, discarded }) => {
      const URL = mapId
        ? makeVoteRoundCacheMapApiPath(contestId, contestState, mapId)
        : makeVoteRoundCacheApiPath(contestId, contestState)
      const payload = {
        ...(discarded ? { discarded } : {}),
        ...(submitted ? { submitted } : {}),
      }
      return axios[type](URL, payload)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([makeVoteRoundCacheApiPath(contestId, contestState), userId])
      },
    },
  )
}

export const useContestVoteRoundConfirmMutation = (contestId, contestState) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const isTiebreakersRound = getIsTiebreakersRound(contestState)
  const URL = makeVoteRoundApiPath(contestId, contestState)

  return useMutation((ids) => axios.post(URL, (isTiebreakersRound && { map_id: ids[0] }) || { map_ids: ids }), {
    onSuccess: () => {
      queryClient.invalidateQueries(URL)
    },
  })
}

export const useContestVoteRoundStartNextMutation = (contestId, contestState) => {
  const { axios, id: userId } = useAuth()
  const queryClient = useQueryClient()
  const URL = makeVoteRoundNextRoundApiPath(contestId, contestState)

  return useMutation(() => axios.post(URL), {
    onSuccess: () => {
      queryClient.invalidateQueries(makeContestApiPath(contestId))
      queryClient.invalidateQueries(makeVoteRoundApiPath(contestId, contestState))
      queryClient.invalidateQueries(getVoteRoundNotDiscardedMapsApiUrl(contestId, contestState))
      queryClient.invalidateQueries([makeVoteRoundCacheApiPath(contestId, contestState), userId])
    },
  })
}

export const useContestJudgeVoteStatusMutation = (contestId, isConfirm) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const URL = makeContestResultApiPath(contestId)
  const JUDGES_STATUS = makeContestJudgesStatusApiPath(contestId)

  return useMutation(() => axios.post(URL, { confirm: isConfirm }), {
    onSuccess: () => {
      queryClient.invalidateQueries(JUDGES_STATUS)
    },
  })
}

export const useContestJudgesStatusQuery = (contestId, enabled) => {
  const { axios } = useAuth()
  const URL = makeContestJudgesStatusApiPath(contestId)

  return useQuery(
    [URL],
    async () => {
      const { data } = await axios.get(URL)

      return data
    },
    {
      enabled: !!contestId && enabled,
    },
  )
}

export const useDeleteContestResultMutation = (contestId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()

  return useMutation(() => axios.delete(makeContestResultApiPath(contestId)), {
    onSettled: () => {
      queryClient.invalidateQueries(makeContestApiPath(contestId))
    },
  })
}

export const useContestWinnersListQuery = (contestId, enabled) => {
  const { axios } = useAuth()
  const URL = makeContestResultApiPath(contestId)

  return useQuery(
    [URL, contestId],
    async () => {
      const { data } = await axios.get(URL)

      return data.data.map((item) => ({
        ...item,
        map: {
          ...getMapFromRelations(data, item.map_id),
          user: getUserFromRelations(data, getMapFromRelations(data, item.map_id).user_id),
        },
      }))
    },
    {
      enabled,
    },
  )
}

export const useContestCommunityVoteQuery = (contestId) => {
  const { axios } = useAuth()
  const URL = makeContestCommunityVotePath(contestId)

  return useQuery(
    [URL],
    async () => {
      const { data } = await axios.get(URL)
      return data
    },
    {
      enabled: !!contestId,
    },
  )
}

export const useContestPublishQuery = (contestId) => {
  const { axios } = useAuth()
  const URL = makeContestPublishApiPath(contestId)
  const queryClient = useQueryClient()
  const contestUrl = makeContestApiPath(contestId)
  return useMutation(() => axios.post(URL), {
    onSuccess: () => {
      queryClient.invalidateQueries(contestUrl)
    },
  })
}

export const useContestJudgeBookmarkingStatus = (contestId) => {
  const { axios } = useAuth()
  const URL = makeContestJudgeBookmarkingStatusApiPath(contestId)

  return useQuery(
    [URL],
    async () => {
      const { data } = await axios.get(URL)

      return {
        ...data,
        isBookmarkingAvailable: !!data?.status,
        isBookmarkingInProgress: data?.status !== 'done',
      }
    },
    {
      enabled: !!contestId,
      cacheTime: 10000,
      staleTime: 5000,
    },
  )
}

// TODO currently not used, but can be helpful in development
export const useForceContestJudgeBookmarking = (contestId) => {
  const { axios } = useAuth()
  const URL = makeContestJudgeBookmarkingStatusApiPath(contestId)

  return useMutation(() => axios.post(URL))
}

export const useContestAcceptInvitationQuery = (contestId) => {
  const { axios, id: userId } = useAuth()
  const URL = makeContestInvitationUserIdApiPath(contestId, userId)
  return useMutation((payload) => axios.put(URL, payload))
}

export const useContestPaymentsQuery = (contestId, isSuperAdmin) => {
  const { axios } = useAuth()
  const URL = makeContestPaymentApiPath(contestId)

  return useQuery(
    [URL],
    async () => {
      const { data } = await axios.get(URL)
      return data.data.map((payment) => ({
        ...payment,
        user: data.relations.users[payment.user_id],
        paymentInfo:
          payment.user_payment_settings !== null && payment.payment_state !== PAYMENTS_STATES[0]
            ? data.relations.payment_types[payment.user_payment_settings[0]?.payment_type_id]
            : null,
      }))
    },
    {
      enabled: !!contestId && isSuperAdmin,
    },
  )
}

export const useContestSentPaymentMutation = (contestId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const URL = makeContestPaymentApiPath(contestId)
  return useMutation((payload) => axios.post(URL, payload), {
    onSuccess: () => {
      queryClient.invalidateQueries([URL])
    },
  })
}

export const useChangeJudgeRoundMutation = (contestId, userId) => {
  const { axios } = useAuth()
  const queryClient = useQueryClient()
  const URL = makeContestInvitationUserIdApiPath(contestId, userId)
  const invalidateURL = makeContestApiPath(contestId)
  return useMutation((payload) => axios.put(URL, payload), {
    onSettled: () => {
      queryClient.invalidateQueries([invalidateURL])
    },
  })
}
