export const ROUTE_LOGIN_HASH = 'login'
export const ROUTE_RESET_PASSWORD_HASH = 'reset-password'
export const ROUTE_RESET_NEW_PASSWORD_HASH = 'reset-new-password'
export const ROUTE_SET_NEW_PASSWORD_HASH = 'set-new-password'
export const ROUTE_USER_FOLLOWERS_HASH = 'followers'
export const ROUTE_USER_FOLLOWING_HASH = 'following'
export const ROUTE_USER_BLOCK_HASH = 'block'
export const ROUTE_USER_REPORT_HASH = 'report'
export const ROUTE_SUBMIT_MAP_HASH = 'submit-map'
export const ROUTE_QUALIFY_MAP_HASH = 'qualify-map/:mapId'
export const ROUTE_SET_NEW_EMAIL = 'set-new-email'

export const ROUTE_ROOT_PATH = '/'

export const ROUTE_LOGIN_PATH = '/login'
export const ROUTE_LOGIN_SOCIAL = '/login/:id'
export const ROUTE_CREATE_NEW_ACCOUNT_PATH = '/create-new-account'

export const ROUTE_CONTESTS_PATH = '/contests'
export const ROUTE_CONTESTS_ARCHIVE_PATH = '/contests/archive'
export const ROUTE_CREATE_CONTEST_PATH = '/contests/create'
export const ROUTE_EDIT_CONTEST_PATH = '/contests/edit'
export const ROUTE_CONTEST_ID_PATH = '/contests/:id'
export const ROUTE_CONTEST_ID_INVITATION_PATH = '/contests/:id/invitation'
export const ROUTE_CONTEST_ID_PATH_PAYMENTS = '/contests/:id/payments'

export const ROUTE_MAPS_PATH = '/maps'
export const ROUTE_CREATE_MAP_PATH = '/maps/create'
export const ROUTE_EDIT_MAP_PATH = '/maps/edit'
export const ROUTE_MAP_ID_PATH = '/maps/:id'

export const ROUTE_PREFABS_PATH = '/prefabs'
export const ROUTE_CREATE_PREFAB_PATH = '/prefabs/create'
export const ROUTE_EDIT_PREFAB_PATH = '/prefabs/edit'
export const ROUTE_PREFAB_ID_PATH = '/prefabs/:id'

export const ROUTE_DOWNLOAD_ENTITY = '/:entity/:id/download'

export const ROUTE_WIKI_PATH = '/guides'
export const ROUTE_WIKI_PATH_WITH_GAME = '/guides/:game'
export const ROUTE_CREATE_WIKI_PATH = '/guides/:game/create'
export const ROUTE_CREATE_WIKI_WITH_TITLE_PATH = '/guides/:game/create/:title'
export const ROUTE_EDIT_WIKI_PATH = '/guides/:game/edit/:id'
export const ROUTE_WIKI_ID_PATH = '/guides/:game/:id'
export const ROUTE_WIKI_NOT_FOUND_PATH = '/guides/:game/:id/404'
export const ROUTE_WIKI_SEARCH_PATH = '/guides/search/:query'

export const ROUTE_COLLECTIONS_PATH = '/collections'
export const ROUTE_COLLECTIONS_ID_PATH = '/collections/:id'

export const ROUTE_EDIT = 'edit'
export const ROUTE_BLOG_PATH = '/news'
export const ROUTE_BLOG_ID_PATH = '/news/:id'
export const ROUTE_BLOG_ID_EDIT_PATH = `${ROUTE_BLOG_ID_PATH}/${ROUTE_EDIT}`
export const ROUTE_BLOG_CREATE_PATH = `${ROUTE_BLOG_PATH}/create`

export const ROUTE_COMMUNITY_FAVORITES_PATH = '/community-favorites'
export const ROUTE_COMMUNITY_FAVORITES_VOTE_PATH = '/community-favorites/vote'
export const ROUTE_COMMUNITY_FAVORITES_ID_PATH = '/community-favorites/:id'

export const ROUTE_PROFILE_PATH = '/profile'
export const ROUTE_PROFILE_OWNER_ID_PATH = '/profile/:id'
export const ROUTE_PROFILE_TABS = {
  MAPS: 'maps',
  COLLECTIONS: 'collections',
  ACTIVITY: 'activity',
  ACCOUNTS: 'accounts',
  BOOKMARKS: 'bookmarks',
  PREFABS: 'prefabs',
  PAYMENTS: 'payments',
}
export const CONTENT_TYPES = {
  MAPS: 'maps',
  PREFABS: 'prefabs',
  CONTESTS: 'contests',
}
export const ROUTE_PROFILE_OWNER_ID_MAPS_PATH = `${ROUTE_PROFILE_OWNER_ID_PATH}/${ROUTE_PROFILE_TABS.MAPS}`
export const ROUTE_PROFILE_OWNER_ID_PREFABS_PATH = `${ROUTE_PROFILE_OWNER_ID_PATH}/${ROUTE_PROFILE_TABS.PREFABS}`
export const ROUTE_PROFILE_OWNER_ID_BOOKMARKS_PATH = `${ROUTE_PROFILE_OWNER_ID_PATH}/${ROUTE_PROFILE_TABS.BOOKMARKS}`
export const ROUTE_PROFILE_OWNER_ID_COLLECTIONS_PATH = `${ROUTE_PROFILE_OWNER_ID_PATH}/${ROUTE_PROFILE_TABS.COLLECTIONS}`
export const ROUTE_PROFILE_OWNER_ID_ACTIVITY_PATH = `${ROUTE_PROFILE_OWNER_ID_PATH}/${ROUTE_PROFILE_TABS.ACTIVITY}`
export const ROUTE_PROFILE_OWNER_SETTINGS_PATH = '/profile/settings'
export const ROUTE_PROFILE_OWNER_SETTINGS_ACCOUNTS_PATH = '/profile/settings/accounts'
export const ROUTE_PROFILE_OWNER_SETTINGS_PAYMENTS_PATH = '/profile/settings/payments'
export const ROUTE_UNSUBSCRIBE_PATH = '/profile/settings/unsubscribe'

export const ROUTE_SEARCH_PATH = '/search'

export const ROUTE_GUIDES_PATH = '/guides'
export const ROUTE_CHAT_PATH = '/chat'
export const ROUTE_TERMS_AND_CONDITIONS_PATH = '/terms-and-conditions'
export const ROUTE_PRIVACY_PATH = '/privacy'

export const ROUTE_OAUTH2_PATH = '/oauth2/authorize'

export const WAYPOINT_URL_REGEXP = /^(https?:\/\/)?(www\.)?halowaypoint\.com(\/[-a-z\\d%_.~+]+)+/
export const YOUTUBE_BASE_REGEXP = /(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/
export const YOUTUBE_REGEXP = /^(http(s)??:\/\/)?(www\.)?((youtube\.com\/watch\?v=)|(youtu.be\/))([a-zA-Z0-9\-_])+/
export const TWEET_REGEXP = /^https?:\/\/(twitter\.com|x\.com)\/(\w+)\/status\/(\d+)/

export const openGraphDescriptions = {
  homepage:
    "Welcome to ForgeHub, a community driven hub for Halo's Forge mode, an in game level editor. Since 2007, ForgeHub has been the definitive place to find and share Forge maps and game modes, join events and competitions, and learn more about level design and level creation. Join ForgeHub to connect with others, share, and collaborate.",
  contests:
    "View ForgeHub's current events including Forge contests, map contests, prefab contests, screenshot contests, and more. Forgers have the opportunity to win prizes, including cash, merch, and more by submitting to contests. Compete to win or just to challenge yourself.",
  mapList:
    "Browse ForgeHub's expansive collection of maps, from community classics to those made by up and coming Forgers. Filter maps by game, player count, supported game modes, and more.",
  prefabsList: 'Prefabs',
  news: "View recent press releases and ForgeHub news. See official announcements from ForgeHub staff as well as Forge related news revolving around Halo's in game editor.",
  favorites:
    "View ForgeHub's community favorites, a curated selection of the communities favorite maps, voted on each month by members.",
  chat: "Chat with other ForgeHub member's on the site, or join our Discord to get more involved. Get support and advice on your current projects or share ideas with others.",
  wiki: "Welcome to ForgeHub's Guide, a place to gather knowledge pertaining to Halo's various Forge modes. Learn and develop your skills to become an expert cartographer by reading through curated guides, educational resources, and the intricacies of in game level design, lighting, scripting, and more!",
}
