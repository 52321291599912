import { useAuth } from 'Components/Auth'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { makeDictionariesApiPath } from './constants'

export const useDictionariesQuery = () => {
  const { axios } = useAuth()
  const dictionariesPath = makeDictionariesApiPath()
  return useQuery(
    dictionariesPath,
    async () => {
      const { data } = await axios.get(dictionariesPath)
      return data
    },
    {
      retry: false,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retryOnMount: false,
    },
  )
}

export const useGameTypeName = (gameTypes = []) => {
  const { data: dictionaries } = useDictionariesQuery()
  const allGameTypes = selectGameTypes(dictionaries)
  const formattedGameTypes = gameTypes?.map((gameType) =>
    typeof gameType === 'object' ? gameType.game_type_id : gameType,
  )

  return useMemo(() => getGameTypesNames(allGameTypes, formattedGameTypes), [formattedGameTypes, allGameTypes])
}

export const usePrefabTypeName = (prefabTypes) => {
  const { data: dictionaries } = useDictionariesQuery()
  const allPrefabTypes = selectPrefabTypes(dictionaries)
  return useMemo(() => getPrefabTypesNames(allPrefabTypes, prefabTypes), [prefabTypes, allPrefabTypes])
}

export const usePrefabCategoryName = (categoryId) => {
  const { data: dictionaries } = useDictionariesQuery()
  const allPrefabCategories = selectPrefabCategories(dictionaries)
  return useMemo(() => getPrefabCategoryName(allPrefabCategories, categoryId), [categoryId, allPrefabCategories])
}

export const usePrimaryGameType = (primaryGameTypeId) => {
  const { data: dictionaries } = useDictionariesQuery()
  const allGameType = selectGameTypes(dictionaries)
  return useMemo(() => getPrimaryGameType(allGameType, primaryGameTypeId), [primaryGameTypeId, allGameType])
}

export const usePrimaryGameTypeName = (primaryGameTypeId) => {
  const gameTypeObject = usePrimaryGameType(primaryGameTypeId)
  return gameTypeObject?.name || ''
}

export const useGameName = (gameIds) => {
  const { data: dictionaries } = useDictionariesQuery()
  const allGames = selectGames(dictionaries)
  return useMemo(() => getGameName(gameIds, allGames), [gameIds, allGames])
}

export const useCategoryName = (categoryIds) => {
  const { data: dictionaries } = useDictionariesQuery()
  const allCategories = selectCategories(dictionaries)
  return useMemo(() => getCategoryName(categoryIds, allCategories), [categoryIds, allCategories])
}

export const useCategoryNameByGameType = (gameTypes) => {
  const { data: dictionaries } = useDictionariesQuery()
  const allCategories = selectCategories(dictionaries)
  const allGameTypes = selectGameTypes(dictionaries)

  const gameTypeId = gameTypes.find(({ isPrimary }) => isPrimary)?.id || gameTypes[0]
  const categoryId = allGameTypes.find(({ id }) => id === gameTypeId)?.category_id

  return useMemo(() => getCategoryName(categoryId, allCategories), [categoryId, allCategories])
}

export const usePlayersCountName = (playersCountIds) => {
  const { data: dictionaries } = useDictionariesQuery()
  const allPlayersCount = selectPlayersCount(dictionaries)
  return useMemo(() => getPlayersCountName(playersCountIds, allPlayersCount), [playersCountIds, allPlayersCount])
}

export const useCategoryFFAOnly = (categoryId) => {
  const { data: dictionaries } = useDictionariesQuery()
  const allCategories = selectCategories(dictionaries)
  return useMemo(() => allCategories.find(({ id }) => id === categoryId)?.is_ffa_only, [categoryId, allCategories])
}

export const selectGameTypes = (dictionaries) =>
  Object.keys(dictionaries?.game_types || [])?.map((item) => ({
    ...dictionaries?.game_types[item],
    id: parseInt(item),
  }))

export const selectCategories = (dictionaries) =>
  Object.keys(dictionaries?.categories || [])?.map((item) => ({
    ...dictionaries?.categories[item],
    id: parseInt(item),
  }))

export const selectPrefabCategories = (dictionaries) =>
  dictionaries
    ? Object.keys(dictionaries['prefabs.categories'] || [])?.map((item) => ({
        ...dictionaries['prefabs.categories'][item],
        id: parseInt(item),
      }))
    : []

export const selectPrefabThemes = (dictionaries) =>
  dictionaries
    ? Object.keys(dictionaries['prefabs.themes'] || [])?.map((item) => ({
        ...dictionaries['prefabs.themes'][item],
        id: parseInt(item),
      }))
    : []
export const selectPrefabTypes = (dictionaries) =>
  dictionaries
    ? Object.keys(dictionaries['prefabs.types'] || [])?.map((item) => ({
        ...dictionaries['prefabs.types'][item],
        id: parseInt(item),
      }))
    : []

export const selectGames = (dictionaries) =>
  Object.keys(dictionaries?.games || [])?.map((item) => ({ ...dictionaries?.games[item], id: parseInt(item) }))

export const selectPlayersCount = (dictionaries) =>
  Object.keys(dictionaries?.players_counts || [])
    ?.map((item) => ({
      ...dictionaries?.players_counts[item],
      id: parseInt(item),
    }))
    .sort((a, b) => a.sort_order - b.sort_order)

export const selectThemes = (dictionaries) =>
  Object.keys(dictionaries?.themes || [])?.map((item) => ({ ...dictionaries?.themes[item], id: parseInt(item) }))

export const selectTypes = (dictionaries) =>
  Object.keys(dictionaries?.types || [])?.map((item) => ({ ...dictionaries?.types[item], id: parseInt(item) }))

export const selectUserGroups = (dictionaries) =>
  Object.keys(dictionaries?.user_groups || [])?.map((item) => ({
    ...dictionaries?.user_groups[item],
    id: parseInt(item),
  }))

export const selectCountries = (dictionaries) =>
  Object.keys(dictionaries?.countries || [])?.map((item) => ({ ...dictionaries?.countries[item], id: parseInt(item) }))

export const getCategoryName = (categoryId, categories) =>
  categories.find((category) => category.id === categoryId)?.name || ''

export const getPlayersCountName = (playersCountId, playersCount) =>
  playersCount.find(({ id }) => id === playersCountId)?.name || ''

export const getGameName = (gameId, games) => games.find(({ id }) => id === gameId)?.name || ''

export const getGameTypesNames = (allGameTypes, gameTypes) =>
  allGameTypes.filter(({ id }) => (gameTypes || []).includes(id)).map(({ name }) => name)

export const getPrefabTypesNames = (allPrefabTypes, prefabTypes) =>
  allPrefabTypes.filter(({ id }) => (prefabTypes || []).includes(id)).map(({ name }) => name)

export const getPrefabCategoryName = (allPrefabCategories, categoryId) =>
  allPrefabCategories.find(({ id }) => id === categoryId)?.name || ''

export const getPrimaryGameType = (allGameTypes, primaryGameTypeId) =>
  allGameTypes.find(({ id }) => primaryGameTypeId === id)
