import { alpha, Box, Button, Typography } from '@mui/material'
import { NotificationContext } from 'Components/Notification/context'
import { useRestoreContestMutation } from 'Queries/Contests'
import { useContext } from 'react'
import { navigateToEditContestPage } from 'Scenes/navigators'
import FAIcon from 'Theme/FAIcon'
import { FA_ICONS } from 'Theme/font-awesome-icon-constants'
import CustomModal from 'Theme/Modal'

import { RestoreDialogButton } from './RestoreDialogButton'

export const RestoreContestDialog = ({ isOpened, handleCloseModal, withEdit = false, contestId }) => {
  const { notification } = useContext(NotificationContext)
  const { mutate: restoreContest } = useRestoreContestMutation(contestId)

  const handleRestore = () => {
    restoreContest(
      {},
      {
        onSuccess: () => {
          handleCloseModal()
          notification.success('Contest has been restored', 'Success')
        },
      },
    )
  }

  const handleEditClick = () => {
    navigateToEditContestPage(contestId)
    handleCloseModal()
  }

  return (
    <CustomModal
      open={isOpened}
      onClose={handleCloseModal}
      title="Restore Contest?"
      description="Are you sure you want to restore the contest? This action will reactivate the contest and make it available for participation"
      footer={
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
        >
          {withEdit && <RestoreDialogButton onClick={handleRestore} />}
          <Box
            display="flex"
            gap={1}
            width="100%"
          >
            <Button
              color="inherit"
              sx={(theme) => ({
                width: 'calc(50% - 4px)',
                height: '40px',
                padding: theme.spacing(1, 2),
                background: theme.palette.background.pale,
              })}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            {withEdit ? (
              <Button
                color="secondary"
                startIcon={
                  <FAIcon
                    iconClassName={FA_ICONS.PEN}
                    iconFontSize={14}
                  />
                }
                sx={(theme) => ({
                  background: '#01a3d214',
                  width: 'calc(50% - 4px)',
                  padding: theme.spacing(1, 2),
                  height: '40px',
                  '&:hover': {
                    background: alpha('#01a3d214', 0.2),
                  },
                })}
                onClick={handleEditClick}
              >
                Edit
              </Button>
            ) : (
              <RestoreDialogButton onClick={handleRestore} />
            )}
          </Box>
        </Box>
      }
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacing(1),
          border: `1px solid ${theme.palette.text.secondary}`,
          padding: '12px 16px',
          borderRadius: theme.spacing(1),
        })}
      >
        <FAIcon
          iconClassName={FA_ICONS.INFO}
          color="secondary.main"
        />
        <Box>
          <Typography
            variant="h6"
            color="text.primary"
          >
            Important!
          </Typography>
          <Typography
            variant="body2"
            color="text.primary"
          >
            All participants will receive a notification
          </Typography>
        </Box>
      </Box>
    </CustomModal>
  )
}
