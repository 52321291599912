import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import ConditionalWrap from 'conditional-wrap'
import { When } from 'react-if'
import { Link } from 'react-router-dom'
import Button from 'Theme/Button'
import Card, { CardContent } from 'Theme/Card'
import Chip from 'Theme/Chip'
import FAIcon from 'Theme/FAIcon'
import { FA_KIT_ICONS } from 'Theme/font-awesome-icon-constants'

import { CollectionActions } from './CollectionActions'
import { CollectionMedia } from './CollectionMedia'
import { OwnerControls } from './OwnerControls'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius * 2,
  },
  primary: {
    height: '484px',
  },
  active: {
    cursor: 'pointer',
    transition: theme.transitions.create(['transform', 'box-shadow']),

    '&:hover': (props) => ({
      transform: props.transformDisabled ? 'scale(1)' : 'scale(1.05)',
      boxShadow: theme.shadows[6],
    }),
  },
  body: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
  },
  starIcon: {
    paddingRight: -theme.spacing(3),
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    height: '100%',
  },
  title: {
    marginBottom: theme.spacing(0.5),
  },
}))

const CollectionCard = ({
  collectionItem,
  empty = false,
  disableClick = false,
  target = '_self',
  variant,
  className,
  adornment,
  newMap = false,
  ownerId,
  onDelete,
  cardAction,
  cardActionIconClassName = '',
  transformDisabled = false,
  isShowUser = true,
  onClick,
  playersCountName,
  categoryName,
  showGameCategory,
  link,
  isLoading,
  image,
  isHaloWaypointUser,
  communityFavItem,
  showGameTypeAndCategory,
  isShowCommunityIcon,
  showCategoryText,
  isShowUserName,
  showContentInfo,
  ...otherProps
}) => {
  const classes = useStyles()

  const isLink = !disableClick && collectionItem?.id
  const isPrimary = variant === 'primary'

  return (
    <Card
      className={clsx(classes.root, className, isLink && classes.active, isPrimary && classes.primary)}
      {...otherProps}
      data-collection-card-id={collectionItem?.id}
    >
      <ConditionalWrap
        condition={!!isLink}
        wrap={(children) => (
          <Box
            component={Link}
            draggable={false}
            className={clsx(classes.link, className)}
            target={target}
            onClick={onClick}
            to={link}
          >
            {children}
          </Box>
        )}
      >
        <CollectionMedia
          image={image}
          title={collectionItem.title}
        />

        <CardContent className={classes.body}>
          <Box
            position="absolute"
            top={0}
            left={0}
            px={2}
            pt={1}
            width="100%"
          >
            <When condition={collectionItem.state === 'draft' && ownerId}>
              <OwnerControls
                isLoading={isLoading}
                onDelete={onDelete}
              />
            </When>

            <When condition={!!cardAction && !!cardActionIconClassName}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={(e) => {
                    e.preventDefault()
                    cardAction()
                  }}
                >
                  <FAIcon
                    iconClassName={cardActionIconClassName}
                    iconFontSize={16}
                  />
                </Button>
              </Box>
            </When>
          </Box>

          <When condition={adornment}>
            <Box
              position="absolute"
              top={0}
              left={0}
              px={2}
              pt={1}
              width="100%"
            >
              {adornment}
            </Box>
          </When>

          <When condition={newMap}>
            <Box mb={3}>
              <Chip
                icon={<FAIcon iconClassName={FA_KIT_ICONS.FORGE} />}
                label={
                  <Typography
                    variant="overline"
                    color="text.primary"
                  >
                    New map
                  </Typography>
                }
                color="secondary"
                size="small"
              />
            </Box>
          </When>

          <When condition={collectionItem.is_hidden}>
            <Box sx={{ marginBottom: '10px' }}>
              <Chip
                label={
                  <Typography
                    variant="overline"
                    color="text.secondary"
                    fontWeight={900}
                  >
                    HIDDEN
                  </Typography>
                }
                sx={{
                  padding: '6px 12px',
                  backgroundColor: 'rgba(0, 0, 0, 0.25)',
                  height: '24px',
                  width: '65px',
                  backdropFilter: 'blur(4px)',
                }}
              />
            </Box>
          </When>

          <When condition={!empty}>
            <CollectionActions
              collectionItem={collectionItem}
              gameTypeName={categoryName}
              isShowUser={isShowUser}
              playersCountName={playersCountName}
              variant={variant}
              showGameCategory={showGameCategory}
              isHaloWaypointUser={isHaloWaypointUser}
              communityFavItem={communityFavItem}
              showGameTypeAndCategory={showGameTypeAndCategory}
              isShowCommunityIcon={isShowCommunityIcon}
              showCategoryText={showCategoryText}
              isShowUserName={isShowUserName}
              showContentInfo={showContentInfo}
            />
          </When>
        </CardContent>
      </ConditionalWrap>
    </Card>
  )
}

export default CollectionCard
