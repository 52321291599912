export const FA_ICONS = {
  ARROW_LEFT: 'fa-solid fa-arrow-left',
  ARROW_DOWN: 'fa-solid fa-sort-down',
  ARROW_RIGHT: 'fa-solid fa-arrow-right',
  ARROW_RIGHT_TO_LINE: 'fa-solid fa-arrow-right-to-line',
  ARROW_RIGHT_ARROW_LEFT: 'fa-sharp fa-solid fa-arrow-right-arrow-left',
  ARROW_DOWN_TO_LINE: 'fa-solid fa-arrow-down-to-line',
  ARROWS_ALL_DIRECTIONS: 'fa-solid fa-arrows-up-down-left-right',
  CHEVRON_LEFT: 'fa-solid fa-chevron-left',
  CHEVRON_RIGHT: 'fa-solid fa-chevron-right',
  CHEVRON_UP: 'fa-solid fa-chevron-up',
  CHEVRON_DOWN: 'fa-solid fa-chevron-down',
  CARET_UP: 'fa-solid fa-caret-up',
  CARET_RIGHT: 'fa-solid fa-caret-right',
  CARET_DOWN: 'fa-solid fa-caret-down',
  CLOSE: 'fa-regular fa-xmark',
  UPLOAD: 'fa-solid fa-upload',
  CIRCLE_XMARK: 'fa-solid fa-circle-xmark',
  EXPAND: 'fa-sharp fa-regular fa-expand',
  SPINNER: 'fa-solid fa-spinner',
  TRASH: 'fa-solid fa-trash',
  TRASH_XMARK: 'fa-solid fa-trash-xmark',
  PLAY: 'fa-solid fa-play',
  DOWNLOAD: 'fa-solid fa-download',
  GEAR: 'fa-solid fa-gear',
  OPTIONS_DOTS: 'fa-solid fa-ellipsis-vertical',
  CIRCLE_QUESTION: 'fa-solid fa-circle-question',
  LOADER: 'fa-regular fa-loader',
  CHECK: 'fa-solid fa-check',
  CHECK_BADGE: 'fa-solid fa-badge-check',
  CHECK_SQUARE: 'fa-solid fa-square-check',
  CHECK_SLOT: 'fa-solid fa-check-to-slot',
  XMARK_SLOT: 'fa-solid fa-xmark-to-slot',
  PLUS: 'fa-solid fa-plus',
  PLUS_CIRCLE: 'fa-solid fa-circle-plus',
  PLUS_OCTOGON: 'fa-solid fa-octagon-plus',
  EQUALS: 'fa-solid fa-equals',
  BAN: 'fa-solid fa-ban',
  BOX_ARCHIVE_SOLID: 'fa-solid fa-box-archive',
  BOX_ARCHIVE_LIGHT: 'fa-light fa-box-archive',
  DATABASE: 'fa-solid fa-database',
  TAG: 'fa-solid fa-tag',
  ANGLE_LEFT: 'fa-solid fa-angle-left',
  ANGLE_RIGHT: 'fa-solid fa-angle-right',
  ANGLE_UP: 'fa-solid fa-angle-up',
  SHARE: 'fa-solid fa-share',
  OPTIONS: 'fa-solid fa-sliders',
  SAVE: 'fa-solid fa-floppy-disk',
  HEXEGON: 'fa-regular fa-hexagon',
  LOCATION: 'fa-solid fa-location-dot',
  HOUSE: 'fa-solid fa-house',
  MOUNTAINS_SUN: 'fa-sharp fa-solid fa-mountain-sun',
  CALENDAR: 'fa-solid fa-calendar',
  CALENDAR_LINES: 'fa-solid fa-calendar-lines',
  CALENDAR_LINES_PEN: 'fa-solid fa-calendar-lines-pen',
  MAP: 'fa-solid fa-map',
  TROPHY: 'fa-solid fa-trophy',
  THIN_TROPHY: 'fa-thin fa-trophy',
  MESSAGE_DOTS: 'fa-solid fa-message-dots',
  MESSAGE_LINES: 'fa-solid fa-message-lines',
  BOOKMARK: 'fa-solid fa-bookmark',
  UNBOOKMARK: 'fa-solid fa-bookmark-slash',
  BOOKMARK_EMPTY: 'fa-regular fa-bookmark',
  FLAG: 'fa-thin fa-flag',
  GRADUATION_CAP: 'fa-solid fa-graduation-cap',
  HEART: 'fa-solid fa-heart',
  HEART_THIN: 'fa-thin fa-heart',
  CLOCK: 'fa-solid fa-clock',
  CLOCK_ROTATE_LEFT: 'fa-solid fa-clock-rotate-left',
  IMAGE: 'fa-solid fa-image',
  FOLDER_IMAGE: 'fa-solid fa-folder-image',
  FOLDER_UPLOAD: 'fa-solid fa-folder-arrow-down',
  USER_PLUS: 'fa-solid fa-user-plus',
  USER_PROFILE: 'fa-solid fa-user',
  USER_MINUS: 'fa-solid fa-user-minus',
  USER_GROUP: 'fa-solid fa-user-group',
  LOGIN: 'fa-solid fa-arrow-right-to-bracket',
  LOGOUT: 'fa-solid fa-arrow-right-from-bracket',
  DOWN_TO_BRACKET: 'fa-solid fa-down-to-bracket',
  ENVELOPE: 'fa-solid fa-envelope',
  SEND: 'fa-solid fa-paper-plane-top',
  PUBLISH: 'fa-sharp fa-solid fa-paper-plane',
  EMAIL_AT: 'fa-solid fa-at',
  SORT: 'fa-solid fa-bars-sort',
  KEY: 'fa-solid fa-key',
  GLOBE: 'fa-solid fa-globe',
  GAMEPAD: 'fa-solid fa-gamepad-modern',
  SKULL: 'fa-solid fa-skull',
  INFO: 'fa-solid fa-circle-info',
  INFO_LIGHT: 'fa-light fa-circle-info',
  AWARD: 'fa-thin fa-award',
  EXCLAMATION: 'fa-solid fa-circle-exclamation',
  WARNING: 'fa-solid fa-triangle-exclamation',
  STAR: 'fa-thin fa-star',
  FILLED_STAR: 'fa-solid fa-star',
  EMPTY_STAR: 'fa-regular fa-star',
  HALF_STAR: 'fa-solid fa-star-half-stroke',
  ROTATE_LEFT: 'fa-light fa-arrow-rotate-left',
  SOLID_ROTATE_LEFT: 'fa-solid fa-rotate-left',
  ROTATE_RIGHT: 'fa-sharp fa-solid fa-rotate-right',
  COMMENTS: 'fa-solid fa-comment',
  HOURGLASS: 'fa-regular fa-hourglass-end',
  PEN: 'fa-solid fa-pen',
  PEN_TO_SQUARE: 'fa-solid fa-pen-to-square',
  EYE_SLASH: 'fa-sharp fa-solid fa-eye-slash',
  EYE: 'fa-solid fa-eye',
  SHIELD: 'fa-solid fa-shield',
  SHIELD_XMARK: 'fa-solid fa-shield-xmark',
  FIRE: 'fa-solid fa-fire',
  PEOPLE_ARROWS: 'fa-solid fa-people-arrows',
  MATCHMAKING: 'fak fa-i-matchmaking-small',
  FRAME: 'fak fa-i-frame',
  RECOM: 'fak fa-i-recom',
  BELL: 'fa-solid fa-bell',
  BELL_SLASH: 'fa-solid fa-bell-slash',
  PREFAB: 'fa-kit fa-i-prefab',
  MAGNIFYING_GLASS: 'fa-solid fa-magnifying-glass',
  BARS: 'fa-solid fa-bars',
  BARS_FILTER: 'fa-solid fa-bars-filter',
  HORROR: 'fa-solid fa-ghost',
  CASTLE: 'fa-solid fa-castle',
  GAVEL: 'fa-thin fa-gavel',
  ANGLE_DOWN: 'fa-solid fa-angle-down',
  SQUARE_PLUS: 'fa-solid fa-square-plus',
  SQUARE: 'fa-solid fa-square',
  GIFT: 'fa-solid fa-gift',
  CLONE: 'fa-solid fa-clone',
  HISTORY: 'fa-solid fa-rectangle-history',

  //Prefab Types
  STATICK: 'fa-solid fa-block-brick',
  DYNAMIC: 'fa-solid fa-escalator',
  SCRIPT: 'fa-solid fa-split',
  INTERACTIVE: 'fa-solid fa-hand-pointer',
  MODE: 'fa-solid fa-gamepad-modern',

  //Prefab Categories
  WEAPONS: 'fa-solid fa-raygun',
  ENVIRONMENT: 'fa-solid fa-tree',
  CHARACTERS: 'fa-solid fa-user-robot',
  GATES: 'fa-solid fa-dungeon',
  VEHICLES: 'fa-sharp fa-solid fa-shuttle-space',
  BARRICADES: 'fa-solid fa-fort',
  CRATES: 'fa-solid fa-treasure-chest',
  ELEVATORS: 'fa-solid fa-conveyor-belt',
  EQUIPMENT: 'fa-solid fa-hand-holding-magic',
  LIGHTS: 'fa-solid fa-lightbulb-on',
  GENERATORS: 'fa-solid fa-engine',
  EFFECTS: 'fa-sharp fa-solid fa-sparkles',
  TEXTURES: 'fa-solid fa-game-board',
  UI_ELEMENTS: 'fa-solid fa-sidebar',
  SOUND: 'fa-solid fa-volume',
  HOLOGRAMS: 'fa-solid fa-vacuum-robot',
  FILTER: 'fa-solid fa-filter',
  SHIRT: 'fa-solid fa-shirt',
  BAG: 'fa-solid fa-bag-shopping',
  SIMPLE_LINK: 'fa-solid fa-link-simple',

  // Brand and Social
  GOOGLE: 'fa-brands fa-google',
  DISCORD: 'fa-brands fa-discord',
  TWITTER: 'fa-brands fa-twitter',
  STEAM: 'fa-brands fa-steam',
  YOUTUBE: 'fa-brands fa-youtube',
  XBOX: 'fa-brands fa-xbox',
  TWITCH: 'fa-brands fa-twitch',
  FACEBOOK_F: 'fa-brands fa-facebook-f',
  INSTAGRAM: 'fa-brands fa-instagram',

  // added for wiki
  SUN: 'fa-solid fa-sun-bright',
  CODE: 'fa-solid fa-code',
  GUN: 'fa-solid fa-gun',
  TOOLBOX: 'fa-solid fa-toolbox',
  TRUCK: 'fa-solid fa-truck-monster',
  SPAWN: 'fa-solid fa-street-view',

  // payments
  DOLLAR_SIGN: 'fa-solid fa-dollar-sign',
  EURO_SIGN: 'fa-solid fa-euro-sign',
  WALLET: 'fa-solid fa-wallet',
}

export const FA_KIT_ICONS = {
  // Game Type icons
  CAMPAIN: 'fak fa-campaign',
  FIREFIGHT: 'fak fa-firefight',
  AESTHETIC: 'fak fa-gt-aesthetic',
  ASSAULT: 'fak fa-gt-assault',
  ATTRITION: 'fak fa-gt-attrition',
  BIG_TEAM_BATTLE: 'fak fa-gt-big-team-battle',
  BREAKOUT: 'fak fa-gt-breakout',
  CAPTURE_THE_FLAG: 'fak fa-gt-capture-the-flag',
  DOMINION: 'fak fa-gt-dominion',
  DUCK_HUNT: 'fak fa-gt-duck-hunt',
  ELIMINATION: 'fak fa-gt-elimination',
  EXCTRACTION: 'fak fa-gt-exctraction',
  EXTERMINATION: 'fak fa-gt-extermination',
  FOOTRACE: 'fak fa-gt-footrace',
  FORGE: 'fak fa-gt-forge',
  GRIFBALL: 'fak fa-gt-grifball',
  INFECTION: 'fak fa-gt-infection',
  INVASION: 'fak fa-gt-invasion',
  JUGGERNAUT: 'fak fa-gt-juggernaut',
  KOTH: 'fak fa-gt-koth',
  LAND_GRAB: 'fak fa-gt-land-grab',
  MINI_GAME: 'fak fa-gt-mini-game',
  ODDBALL: 'fak fa-gt-oddball',
  PARTY_GAME: 'fak fa-gt-party-game',
  PUZZLE: 'fak fa-gt-puzzle',
  RACE: 'fak fa-gt-race',
  RALLYRACE: 'fak fa-gt-rallyrace',
  REGICIDE: 'fak fa-gt-regicide',
  RICOCHET: 'fak fa-gt-ricochet',
  RIFTBALL: 'fak fa-gt-riftball',
  ROAMING_KING: 'fak fa-gt-roaming-king',
  SLAYER: 'fak fa-gt-slayer',
  STOCKPILE: 'fak fa-gt-stockpile',
  STRONGHOLDS: 'fa-kit fa-gt-strongholds',
  SUMO: 'fak fa-gt-sumo',
  TERRITORIES: 'fak fa-gt-territories',
  VIP: 'fak fa-gt-vip',
  WARZONE: 'fak fa-gt-warzone',
  KING_OF_THE_HILL: 'fak fa-gt-koth',
  TOTAL_CONTROL: 'fa-kit fa-gt-total-control',

  //Prefab Category
  PROPS: 'fa-kit fa-i-props',

  // Interface icons
  PREFAB: 'fa-kit fa-i-prefab',
  ROADMAP: 'fak fa-i-roadmap',
  SUBMIT_MAP_PLUS: 'fak fa-i-map-plus',
  SUBMIT_PREFAB_PLUS: 'fa-kit fa-i-prefab-plus',
  MATCHMAKING: 'fak fa-i-matchmaking-small',
  FRAME: 'fak fa-i-frame',
  RECOM: 'fak fa-i-recom',
}
