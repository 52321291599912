import { CircularProgress, useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import Button from 'Theme/Button'

import FAIcon from '../FAIcon'
import { FA_ICONS } from '../font-awesome-icon-constants'
import { BREAKPOINT_MIN_MD } from '../stylesUtil'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '-30px -30px 60px rgba(0, 0, 0, 0.2), 30px 30px 60px rgba(0, 0, 0, 0.4)',
    borderRadius: theme.shape.borderRadius * 2,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  closeIcon: {
    color: theme.palette.text.tertiary,
    zIndex: 100,
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(4),
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      right: theme.spacing(5),
    },
  },
  helperContainer: {
    padding: theme.spacing(1),
    color: theme.palette.text.tertiary,
    background: 'rgba(1, 163, 210, 0.05)',
    borderRadius: theme.shape.borderRadius,
  },
}))

const CustomModal = ({
  open,
  type = 'default',
  title,
  description,
  helperText,
  okText,
  cancelText,
  onOk,
  onClose,
  isLoading,
  fullWidth,
  children,
  isOkBtnDisabled = false,
  footer,
  header,
  sx,
  width = '424px',
  withPadding = true,
  exitButtonStyle = {},
  gap = '24px',
  modalPadding,
}) => {
  const classes = useStyles()
  const isBiggerThanSMDScreen = useMediaQuery(BREAKPOINT_MIN_MD)
  const padding = modalPadding || (!withPadding ? '0px' : isBiggerThanSMDScreen ? '32px' : '40px')
  return (
    <Dialog
      open={open}
      fullWidth={fullWidth}
      onClose={onClose}
      sx={sx}
      onClick={(e) => e.preventDefault()}
    >
      <DialogContent
        className={classes.root}
        sx={{ width: fullWidth ? '100%' : width, padding, gap }}
      >
        <FAIcon
          iconFontSize={24}
          iconClassName={FA_ICONS.CLOSE}
          style={exitButtonStyle}
          className={classes.closeIcon}
          onClick={onClose}
        />
        <Box className={classes.content}>
          {header
            ? header
            : !!title && (
                <Typography
                  variant="h4"
                  color="text.primary"
                >
                  {title}
                </Typography>
              )}
          {!!description && (
            <Typography
              variant="body2"
              color="text.primary"
            >
              {description}
            </Typography>
          )}
          {helperText && (
            <Box className={classes.helperContainer}>
              <Typography
                variant="body2"
                color="text.tertiary"
              >
                {helperText}
              </Typography>
            </Box>
          )}
          {children}
        </Box>
        {footer ? (
          footer
        ) : (
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
          >
            {cancelText && (
              <Button
                variant="contained"
                color="inherit"
                onClick={onClose}
              >
                {cancelText}
              </Button>
            )}
            {okText && (
              <Button
                variant="contained"
                color={type === 'danger' ? 'error' : 'primary'}
                sx={{ gap: '16px' }}
                onClick={onOk}
                disabled={isOkBtnDisabled}
              >
                {okText}
                {isLoading && (
                  <CircularProgress
                    color="inherit"
                    size="16px"
                  />
                )}
              </Button>
            )}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default CustomModal
