import { Button } from '@mui/material'
import { useState } from 'react'
import FAIcon from 'Theme/FAIcon'
import { FA_ICONS } from 'Theme/font-awesome-icon-constants'

import { RestoreContestDialog } from './RestoreContestDialog'

export const RestoreContest = ({ contestId, withEdit }) => {
  const [showDialog, setShowDialog] = useState(false)

  const handleClick = (event) => {
    event?.preventDefault()
    setShowDialog(true)
  }
  const handleCloseModal = (event) => {
    event?.preventDefault()
    setShowDialog(false)
  }
  return (
    <>
      <Button
        variant="contained"
        startIcon={<FAIcon iconClassName={FA_ICONS.SOLID_ROTATE_LEFT} />}
        sx={{
          marginLeft: '40px',
        }}
        onClick={handleClick}
      >
        Restore
      </Button>
      <RestoreContestDialog
        handleCloseModal={handleCloseModal}
        isOpened={showDialog}
        contestId={contestId}
        withEdit={withEdit}
      />
    </>
  )
}
