import { Box, Step, StepLabel, Stepper } from '@mui/material'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import React from 'react'
import { Else, If, Then } from 'react-if'
import FAIcon from 'Theme/FAIcon'
import { FA_ICONS } from 'Theme/font-awesome-icon-constants'

import { getDayMonth } from '../../utils/date-utils'
import Timer from '../_common/Timer'
import { getContestStepperState } from './utils'

const useStyles = makeStyles((theme) => ({
  stepper: {
    background: 'rgba(32, 33, 38, 0.3)',
    boxShadow: '0px 0px 0.5px 0.5px rgba(10, 8, 39, 0.05)',
    backdropFilter: 'blur(8px)',
    borderRadius: '16px',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    minWidth: '211px',
    '& .MuiStep-root': { position: 'relative' },
    '& .MuiStepLabel-labelContainer': {
      position: 'absolute',
      left: 40,
      top: 0,
      height: 30,
      display: 'flex',
      alignItems: 'center',
    },

    '& .MuiStepConnector-line': {
      height: 40,
      width: 3,
      backgroundColor: theme.palette.background.default,
      border: 'none',
    },
  },
  defaultConnector: {
    '& .Mui-active': {
      '& .MuiStepConnector-line': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '& .Mui-completed': {
      '& .MuiStepConnector-line': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  publishConnector: {
    '& .Mui-completed': {
      '& .MuiStepConnector-line': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
}))

const getStylesIconContainer = (step, currentStep) => {
  const background = () => {
    if (currentStep === 4) return 'secondary.main'
    if (currentStep < step) return 'background.default'
    return 'primary.main'
  }
  return {
    backgroundColor: background(),
    color: currentStep < step && 'text.tertiary',
    borderRadius: '50%',
    width: '28px',
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
  }
}

const roundIcon = (active) => (
  <Box
    width={active ? 12 : 9}
    height={active ? 12 : 9}
    borderRadius="50%"
    sx={{ backgroundColor: active ? 'text.primary' : 'text.tertiary' }}
  />
)

const getSubmissionIcon = (activeStep) => (
  <Box sx={getStylesIconContainer(0, activeStep)}>
    <If condition={activeStep < 1}>
      <Then>{roundIcon(true)}</Then>
      <Else>
        <FAIcon
          iconClassName={FA_ICONS.CHECK}
          iconFontSize={14}
        />
      </Else>
    </If>
  </Box>
)

const getQualificationIcon = (activeStep) => (
  <Box sx={getStylesIconContainer(1, activeStep)}>
    <If condition={activeStep > 1}>
      <Then>
        <FAIcon
          iconClassName={FA_ICONS.CHECK}
          iconFontSize={14}
        />
      </Then>
      <Else>{roundIcon(activeStep === 1)}</Else>
    </If>
  </Box>
)

const getEvaluationIcon = (activeStep) => (
  <Box sx={getStylesIconContainer(2, activeStep)}>
    <If condition={activeStep > 2}>
      <Then>
        <FAIcon
          iconClassName={FA_ICONS.CHECK}
          iconFontSize={14}
        />
      </Then>
      <Else>{roundIcon(activeStep === 2)}</Else>
    </If>
  </Box>
)

const getFinalIcon = (activeStep) => (
  <Box sx={getStylesIconContainer(3, activeStep)}>
    <FAIcon
      iconClassName={FA_ICONS.TROPHY}
      iconFontSize={14}
    />
  </Box>
)

const ContestStatesStep = ({ contest }) => {
  const activeStep = getContestStepperState(contest.state)
  const classes = useStyles()

  const stepDescription = (endDate, value) => {
    if (activeStep === 0 && value === 0) return getDayMonth(endDate)
    if (activeStep === value) return 'In Progress'
    if (activeStep < value) return ''
    return getDayMonth(endDate)
  }

  const stepList = [
    {
      value: 0,
      title: 'Submission',
      endDate: contest.time_add_maps_end,
      timer: contest.time_add_maps_countdown_sec,
      icon: getSubmissionIcon(activeStep),
    },
    {
      value: 1,
      title: '0: Qualification',
      endDate: contest.time_qualification_end,
      icon: getQualificationIcon(activeStep),
    },
    {
      value: 2,
      title: '1: Evaluation',
      endDate: contest.time_evaluation_end,
      icon: getEvaluationIcon(activeStep),
    },
    {
      value: 3,
      title: '2: Final',
      endDate: contest.time_final_end,
      icon: getFinalIcon(activeStep),
    },
  ]

  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      className={clsx(classes.stepper, activeStep === 4 ? classes.publishConnector : classes.defaultConnector)}
    >
      {stepList.map((step) => (
        <Step key={step.title}>
          <StepLabel icon={step.icon}>
            <Typography
              variant="h6"
              color="text.secondary"
            >
              {step.title}
            </Typography>
            <Typography
              variant="caption"
              color="text.tertiary"
            >
              {stepDescription(step.endDate, step.value)}
              {step.timer !== 0 && step.timer && <Timer seconds={step.timer} />}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default ContestStatesStep
