import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import PropTypes from 'prop-types'
import { When } from 'react-if'
import FAIcon from 'Theme/FAIcon'
import { FA_ICONS } from 'Theme/font-awesome-icon-constants'

const placesState = {
  first: {
    placeIcon: FA_ICONS.THIN_TROPHY,
    styleIcon: { color: '#FFFBD7', filter: 'drop-shadow(0px 0px 11px #FF9E03)' },
    background: 'rgba(197, 172, 42, 0.2)',
  },
  second: {
    placeIcon: FA_ICONS.THIN_TROPHY,
    styleIcon: { color: '#D1D1D1', filter: 'drop-shadow(0px 0px 11px #B5B5B5)' },
    background: 'rgba(166, 166, 166, 0.2)',
  },
  third: {
    placeIcon: FA_ICONS.THIN_TROPHY,
    styleIcon: { color: '#CA8D6B', filter: 'drop-shadow(0px 0px 11px #983803)' },
    background: 'rgba(197, 107, 42, 0.2)',
  },
  fourth: {
    placeIcon: FA_ICONS.THIN_TROPHY,
    styleIcon: { color: '#F3FDFF', filter: 'drop-shadow(0px 0px 11px #00c6ff)' },
    background: 'none',
  },
  default: {
    placeIcon: FA_ICONS.THIN_TROPHY,
    styleIcon: { color: '#F3FDFF', filter: 'drop-shadow(0px 0px 11px #00c6ff)' },
    background: 'none',
  },
  liked: {
    placeIcon: FA_ICONS.HEART_THIN,
    styleIcon: { color: '#FFE1E1', filter: 'drop-shadow(0px 0px 11px #FF0000)' },
    background: 'none',
  },
}

const TrophyIcon = ({
  place = 'default',
  size = 37,
  style,
  withBackground = false,
  withNumberInGoblet = false,
  number,
  text,
  containerStyles,
  prize,
  withBackgroundWithoutText = false,
  fontSize = 14,
  ...rest
}) => {
  const { placeIcon, styleIcon, background } = placesState[place]

  const stylesForContainer = () => {
    if (withBackground) {
      return {
        height: '64px',
        width: '145px',
        position: 'absolute',
        top: '15px',
        left: '15px',
        display: 'flex',
        borderRadius: '16px',
        background: background,
        backdropFilter: 'blur(4px)',
        justifyContent: 'space-around',
        alignItems: 'center',
        ...containerStyles,
      }
    }
    if (withNumberInGoblet) {
      return {
        position: 'relative',
        ...containerStyles,
      }
    }
    return containerStyles
  }

  return (
    <Box sx={stylesForContainer()}>
      <FAIcon
        iconClassName={placeIcon}
        style={style ?? styleIcon}
        iconFontSize={size}
        {...rest}
      />
      <When condition={withNumberInGoblet}>
        <Box
          width="100%"
          top={place === 'liked' ? `${size < 31 ? size / 5 : size / 7}%` : '-10%'}
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="absolute"
        >
          <Typography
            variant="h6"
            width="20px"
            height="20px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            color={styleIcon.color}
            fontSize={fontSize}
          >
            {number}
          </Typography>
        </Box>
      </When>
      <When condition={!withBackgroundWithoutText}>
        <When condition={withBackground}>
          <Box>
            <Typography
              variant="h6"
              color="text.primary"
              marginRight="10px"
            >
              {text}
            </Typography>
            <When condition={prize}>
              <Typography
                lineHeight="24px"
                fontSize="12px"
                variant="h6"
                color="text.primary"
              >
                ${prize}
              </Typography>
            </When>
          </Box>
        </When>
      </When>
    </Box>
  )
}

TrophyIcon.propTypes = {
  place: PropTypes.oneOf(['first', 'second', 'third', 'fourth', 'default', 'liked']),
  size: PropTypes.number,
  numberInGoblet: PropTypes.number,
  withNumberInGoblet: PropTypes.bool,
  withBackground: PropTypes.bool,
  prize: PropTypes.number,
  number: PropTypes.number,
  text: PropTypes.string,
}

export default TrophyIcon
