import FAIcon from 'Theme/FAIcon'
import { FA_ICONS, FA_KIT_ICONS } from 'Theme/font-awesome-icon-constants'

const GAME_TYPE_ICONS = {
  core: FA_KIT_ICONS.SLAYER,
  infection: FA_KIT_ICONS.INFECTION,
  campaign: FA_KIT_ICONS.CAMPAIN,
  firefight: FA_KIT_ICONS.FIREFIGHT,
  elimination: FA_KIT_ICONS.ATTRITION,
  'mini-game': FA_KIT_ICONS.MINI_GAME,
  'mini game': FA_KIT_ICONS.MINI_GAME,
  strongholds: FA_KIT_ICONS.STRONGHOLDS,
  'total control': FA_KIT_ICONS.TOTAL_CONTROL,
  race: FA_KIT_ICONS.RACE,
  grifball: FA_KIT_ICONS.GRIFBALL,
  aesthetics: FA_KIT_ICONS.AESTHETIC,
  aesthetic: FA_KIT_ICONS.AESTHETIC,
  puzzle: FA_KIT_ICONS.PUZZLE,
  extermination: FA_KIT_ICONS.EXTERMINATION,
  'extermination-1': FA_KIT_ICONS.EXTERMINATION,
  regicide: FA_KIT_ICONS.REGICIDE,
  'team slayer': FA_KIT_ICONS.SLAYER,
  assault: FA_KIT_ICONS.ASSAULT,
  'linear infection': FA_KIT_ICONS.INFECTION,
  'multi flag': FA_KIT_ICONS.CAPTURE_THE_FLAG,
  ricochet: FA_KIT_ICONS.RICOCHET,
  territories: FA_KIT_ICONS.TERRITORIES,
  attrition: FA_KIT_ICONS.ATTRITION,
  slayer: FA_KIT_ICONS.SLAYER,
  'ffa-slayer': FA_KIT_ICONS.SLAYER,
  invasion: FA_KIT_ICONS.INVASION,
  oddball: FA_KIT_ICONS.ODDBALL,
  riftball: FA_KIT_ICONS.RIFTBALL,
  vip: FA_KIT_ICONS.VIP,
  breakout: FA_KIT_ICONS.BREAKOUT,
  'foot-race': FA_KIT_ICONS.FOOTRACE,
  juggernaut: FA_KIT_ICONS.JUGGERNAUT,
  'roaming king': FA_KIT_ICONS.ROAMING_KING,
  'capture the flag': FA_KIT_ICONS.CAPTURE_THE_FLAG,
  'one flag': FA_KIT_ICONS.CAPTURE_THE_FLAG,
  'neutral flag': FA_KIT_ICONS.CAPTURE_THE_FLAG,
  forge: FA_KIT_ICONS.FORGE,
  koth: FA_KIT_ICONS.KOTH,
  'party game': FA_KIT_ICONS.PARTY_GAME,
  dominion: FA_KIT_ICONS.DOMINION,
  'grif ball': FA_KIT_ICONS.GRIFBALL,
  'land grab': FA_KIT_ICONS.LAND_GRAB,
  'duck hunt': FA_KIT_ICONS.DUCK_HUNT,
  stockpile: FA_KIT_ICONS.STOCKPILE,
  'liear-infection': FA_KIT_ICONS.INFECTION,
  'rally-race': FA_KIT_ICONS.RALLYRACE,
  sumo: FA_KIT_ICONS.SUMO,
  'husky raid': FA_KIT_ICONS.CAPTURE_THE_FLAG,
  hivemind: FA_KIT_ICONS.INFECTION,
  'last stand': FA_KIT_ICONS.INFECTION,
  'last spartan standing': FA_KIT_ICONS.ATTRITION,
  'rocket race': FA_KIT_ICONS.RACE,
  extraction: FA_KIT_ICONS.EXCTRACTION,
  horde: FA_KIT_ICONS.INFECTION,
  'king of the hill': FA_KIT_ICONS.KING_OF_THE_HILL,

  props: FA_KIT_ICONS.PROPS,
  weapons: FA_ICONS.WEAPONS,
  environment: FA_ICONS.ENVIRONMENT,
  characters: FA_ICONS.CHARACTERS,
  'doors and gates': FA_ICONS.GATES,
  vehicles: FA_ICONS.VEHICLES,
  elevators: FA_ICONS.ELEVATORS,
  barricades: FA_ICONS.BARRICADES,
  crates: FA_ICONS.CRATES,
  lights: FA_ICONS.LIGHTS,
  generators: FA_ICONS.GENERATORS,
  equipment: FA_ICONS.EQUIPMENT,
  holograms: FA_ICONS.HOLOGRAMS,
  'special effects': FA_ICONS.EFFECTS,
  sounds: FA_ICONS.SOUND,
  'ui elements': FA_ICONS.UI_ELEMENTS,
  textures: FA_ICONS.TEXTURES,

  static: FA_ICONS.STATICK,
  dynamic: FA_ICONS.DYNAMIC,
  script: FA_ICONS.SCRIPT,
  interactive: FA_ICONS.INTERACTIVE,
  mode: FA_ICONS.MODE,
}

const GameTypeIcon = ({ size = 'small', gameType, color, ...rest }) => {
  let iconFontSize
  if (Number.isInteger(size)) {
    iconFontSize = size
  } else {
    switch (size) {
      case 'large':
        iconFontSize = 40
        break
      case 'small':
      default:
        iconFontSize = 16
    }
  }

  return gameType && GAME_TYPE_ICONS[gameType] ? (
    <FAIcon
      iconClassName={GAME_TYPE_ICONS[gameType]}
      iconFontSize={iconFontSize}
      color={color}
      {...rest}
    />
  ) : (
    <></>
  )
}

export default GameTypeIcon
