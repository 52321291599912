import { alpha, Button } from '@mui/material'
import FAIcon from 'Theme/FAIcon'
import { FA_ICONS } from 'Theme/font-awesome-icon-constants'

export const RestoreDialogButton = ({ onClick }) => (
  <Button
    sx={(theme) => ({
      padding: theme.spacing(1, 2),
      background: '#00ae7014',
      flexGrow: 1,
      height: '40px',
      '&:hover': {
        background: alpha('#00ae7014', 0.2),
      },
    })}
    onClick={onClick}
    startIcon={<FAIcon iconClassName={FA_ICONS.SOLID_ROTATE_LEFT} />}
  >
    Restore
  </Button>
)
