import Box from '@mui/material/Box'
import { alpha } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React from 'react'

const InfoCard = ({ title, value }) => (
  <Box
    p={1.5}
    sx={(theme) => ({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 64,
      height: 64,
      background: alpha(theme.palette.background.dark, 0.25),
      backdropFilter: 'blur(8px)',
      borderRadius: theme.spacing(2.5),
    })}
  >
    {value}
    <Typography
      variant="caption"
      color="text.tertiary"
    >
      {title}
    </Typography>
  </Box>
)

export default InfoCard
