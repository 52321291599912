const API_ROOT = '/api'

const WIKI_API = ['www.forgehub.com', 'forgehub.com', 'beta.forgehub.com'].includes(window.location.host)
  ? 'https://wiki.forgehub.com'
  : 'https://wiki.dev.forgehub.com'
const origin = window.location.origin
const wikiNamespaces = '3000|4000|0'

const DICTIONARIES_PATH = '/dictionaries'
const CONTESTS_PATH = '/contests'
const MAPS_PATH = '/maps'
const FILTERS_PATH = '/filters'
const BOOKMARK_PATH = '/bookmark'
const ARCHIVE_PATH = '/archive'
const RESTORE_PATH = '/restore'
const BOOKMARKED_PATH = '/bookmarked'
const UNBOOKMARKED_PATH = '/unbookmarked'
const BOOKMARK_PROGRESS_PATH = '/bookmark_progress'
const ANNOUNCEMENTS_PATH = '/announcements'
const COMMENTS_PATH = '/comments'
const RATINGS_PATH = '/ratings'
const SUBMISSION_DEADLINES_PATH = '/submission_deadlines'
const DOWNLOAD_PATH = '/download'
const SUBMISSION_PATH = '/submission'
const QUALIFICATION_PATH = '/qualification'
const QUALIFICATION_CHECKLIST_PATH = '/checklist'
const QUALIFICATION_DISCARD_PATH = '/discard'
const QUALIFICATION_ACCEPT_PATH = '/accept'
const NEXT_ROUND_PATH = '/next'
const CONTEST_CACHE_PATH = '/cache'
const PRESET_PATH = '/preset'
const HOME_PAGE_MAPS_PATH = '/home_page_maps'
const RESULT_PATH = '/result'
const STATUS_PATH = '/status'
const INFO_PATH = '/info'
const COMMUNITY_VOTE_PATH = '/community_vote'
const VOTE_PATH = '/vote'
const PUBLISH_PATH = '/publish '
const USERS_PATH = '/users'
const USER_PATH = '/user'
const FOLLOW_PATH = '/follow'
const COMMUNITY_FAVORITES_PATH = '/community_favorites'
const CURRENT_PATH = '/current'
const LIKES_PATH = '/likes'
const NEWS_PATH = '/news'
const IMPORT_PATH = '/import'
const THEME_PATH = '/themes'
const IMAGES = '/images'
const EMAILS_PATH = '/emails'
const SUBSCRIBE_PATH = '/subscribe'
const FAVORITES_PATH = '/favorites'
const JUDGES_PATH = '/judges'
const PAYMENT_TYPES = '/payment_types'
const PAYMENT_SETTINGS = '/payment_settings'
const PAYMENTS = '/payments'
const ORIGINAL_MAPS_PATH = '/343i'
const NOTES_PATH = '/notes'
const COLLECTIONS_PATH = '/collections'

export const makeUserPaymentSettingsPath = (userId) => `${makeUserApiPath(userId)}${PAYMENT_SETTINGS}`

export const makePaymentsTypePath = () => `${API_ROOT}${PAYMENT_TYPES}`

export const makeSubscriptionApiPath = () => `${API_ROOT}${EMAILS_PATH}${SUBSCRIBE_PATH}`

export const makePresetHomeApiPath = () => `${API_ROOT}${PRESET_PATH}${HOME_PAGE_MAPS_PATH}`

export const makeDictionariesApiPath = () => `${API_ROOT}${DICTIONARIES_PATH}`

export const makeContestsApiPath = () => `${API_ROOT}${CONTESTS_PATH}`

export const makeMapsQueryPath = () => `${API_ROOT}${MAPS_PATH}`

export const makeMapsFiltersQueryPath = () => `${makeMapsQueryPath()}${FILTERS_PATH}`

export const makeMapsBookmarkedQueryPath = () => `${makeMapsQueryPath()}${BOOKMARKED_PATH}`

export const makeMapsUnbookmarkedQueryPath = () => `${makeMapsQueryPath()}${UNBOOKMARKED_PATH}`

export const makeMapsOriginalQueryPath = () => `${makeMapsQueryPath()}${ORIGINAL_MAPS_PATH}`

export const makeUsersApiPath = () => `${API_ROOT}${USERS_PATH}`

export const makeContentApiPath = (type) => `${API_ROOT}/${type}`

export const makeContentApiFavoritePath = (type, id) => `${makeContentApiPath(type)}/${id}${FAVORITES_PATH}`

export const makeCommunityFavoritesApiPath = () => `${API_ROOT}${COMMUNITY_FAVORITES_PATH}`

export const makeImportPath = () => `${API_ROOT}${IMPORT_PATH}`

export const makeInfoPath = () => `${API_ROOT}${INFO_PATH}`

export const makeUserContentPath = (type) => `${makeContentApiPath(type)}${USERS_PATH}`

export const makeDownloadEntityPath = (entity, id) => `${API_ROOT}/${entity}/${id}${DOWNLOAD_PATH}`

export const makeCommunityFavoriteApiPath = (comFavoriteId) => `${makeCommunityFavoritesApiPath()}/${comFavoriteId}`

export const makeCurrentCommunityFavoriteApiPath = () => `${makeCommunityFavoritesApiPath()}${CURRENT_PATH}`

export const makeCommunityFavoriteUserVoteApiPath = () => `${makeCommunityFavoritesApiPath()}${USER_PATH}${VOTE_PATH}`

export const makeMapApiPath = (mapId) => `${makeMapsQueryPath()}/${mapId}`

export const makeContentIdApiPath = (id, type) => `${makeContentApiPath(type)}/${id}`

export const makeContentNotesIdApiPath = (id, type) => `${makeContentIdApiPath(id, type)}${NOTES_PATH}`

export const makeContentLikesApiPath = (id, type) => `${makeContentIdApiPath(id, type)}${LIKES_PATH}`

export const makeContentBookmarkApiPath = (id, type) => `${makeContentIdApiPath(id, type)}${BOOKMARK_PATH}`

export const makeContestApiPath = (contestId) => `${makeContestsApiPath()}/${contestId}`

export const makeContestPaymentApiPath = (contestId) => `${makeContestApiPath(contestId)}${PAYMENTS}`

export const makeContestArchiveApiPath = (contestId) => `${makeContestApiPath(contestId)}${ARCHIVE_PATH}`

export const makeContestRestoreApiPath = (contestId) => `${makeContestApiPath(contestId)}${RESTORE_PATH}`

export const makeContestCommunityVotePath = (contestId) => `${makeContestApiPath(contestId)}${COMMUNITY_VOTE_PATH}`

export const makeContestPublishApiPath = (contestId) => `${makeContestApiPath(contestId)}${PUBLISH_PATH}`

export const makeContestJudgeBookmarkingStatusApiPath = (contestId) =>
  `${makeContestApiPath(contestId)}${BOOKMARK_PROGRESS_PATH}`

export const makeContestSubmissionDeadlinesApiPath = () => `${API_ROOT}${SUBMISSION_DEADLINES_PATH}`

export const makeContestSubmissionMapCandidatesApiPath = (contestId) =>
  `${makeContestApiPath(contestId)}${SUBMISSION_PATH}${MAPS_PATH}`

export const makeContestSubmissionContentCandidatesApiPath = (contestId, type) =>
  `${makeContestApiPath(contestId)}${SUBMISSION_PATH}/${type}`

export const makeContestAnnouncementApiPath = (contestId, id) =>
  `${makeContestApiPath(contestId)}${ANNOUNCEMENTS_PATH}/${id}`

export const makeContestMapsApiPath = (contestId) => `${makeContestApiPath(contestId)}${MAPS_PATH}`

export const makeContestContentApiPath = (contestId, type) => `${makeContestApiPath(contestId)}/${type}`

export const makeContestSingleMapApiPath = (contestId, mapId) => `${makeContestMapsApiPath(contestId)}/${mapId}`

export const makeContestSingleContentApiPath = (contestId, prefabId, type) =>
  `${makeContestContentApiPath(contestId, type)}/${prefabId}`

export const makeContestQualificationApiPath = (contestId) => `${makeContestApiPath(contestId)}${QUALIFICATION_PATH}`

export const makeContestQualificationNextApiPath = (contestId) =>
  `${makeContestQualificationApiPath(contestId)}${NEXT_ROUND_PATH}`

export const makeMapQualificationCheckListApiPath = (contestId) =>
  `${makeContestQualificationApiPath(contestId)}${QUALIFICATION_CHECKLIST_PATH}`

export const makeQualificationMapApiPath = (contestId, mapId) =>
  `${makeContestQualificationApiPath(contestId)}/${mapId}`

export const makeQualificationDiscardMapApiPath = (contestId, mapId) =>
  `${makeQualificationMapApiPath(contestId, mapId)}${QUALIFICATION_DISCARD_PATH}`

export const makeQualificationAcceptMapApiPath = (contestId, mapId) =>
  `${makeQualificationMapApiPath(contestId, mapId)}${QUALIFICATION_ACCEPT_PATH}`

export const makeVoteRoundApiPath = (contestId, contestState) => `${makeContestApiPath(contestId)}/${contestState}`

export const makeVoteRoundNextRoundApiPath = (contestId, contestState) =>
  `${makeVoteRoundApiPath(contestId, contestState)}${NEXT_ROUND_PATH}`

export const makeVoteRoundCacheApiPath = (contestId, contestState) =>
  `${makeContestApiPath(contestId)}${CONTEST_CACHE_PATH}/${contestState}`

export const makeVoteRoundCacheMapApiPath = (contestId, contestState, mapId) =>
  `${makeVoteRoundCacheApiPath(contestId, contestState)}${MAPS_PATH}/${mapId}`

export const makeContestResultApiPath = (contestId) => `${makeContestApiPath(contestId)}${RESULT_PATH}`

export const makeContestJudgesStatusApiPath = (contestId) => `${makeContestResultApiPath(contestId)}${STATUS_PATH}`

export const makeContestCommentsPath = (contestId) => `${makeContestApiPath(contestId)}${COMMENTS_PATH}`

export const makeMapThemesPath = (mapId, themeId) => `${makeMapApiPath(mapId)}${THEME_PATH}/${themeId}`

export const makeContentCommentsPath = (prefabId, type) => `${makeContentIdApiPath(prefabId, type)}${COMMENTS_PATH}`

export const makeContentRatingsCommentsPath = (id, type) =>
  `${makeContentIdApiPath(id, type)}${RATINGS_PATH}${COMMENTS_PATH}`

export const makeContentRatingsPath = (id, type) => `${makeContentIdApiPath(id, type)}${RATINGS_PATH}`

export const makeUserApiPath = (userId) => `${makeUsersApiPath()}/${userId}`

export const makeUserFollowApiPath = (userId) => `${makeUserApiPath(userId)}${FOLLOW_PATH}`

export const makeUserContentApiPath = (userId, type) => `${makeUserApiPath(userId)}/${type}`

export const makeUserChangeFollowApiPath = (userId, followedId) => `${makeUserFollowApiPath(userId)}/${followedId}`

export const makeCommunityFavoriteLikeApiPath = (comFavoriteId) =>
  `${makeCommunityFavoriteApiPath(comFavoriteId)}${LIKES_PATH}`

export const makeCommunityFavoriteCommentsPath = (comFavoriteId) =>
  `${makeCommunityFavoriteApiPath(comFavoriteId)}${COMMENTS_PATH}`

export const makeCommunityFavoriteCommentsReplyPath = (comFavoriteId, commentId) =>
  `${makeCommunityFavoriteCommentsPath(comFavoriteId)}/${commentId}`

export const makeNewsApiPath = () => `${API_ROOT}${NEWS_PATH}`

export const makeArticleIdApiPath = (id) => `${makeNewsApiPath()}/${id}`

export const makeArticleIdCommentsApiPath = (id) => `${makeArticleIdApiPath(id)}${COMMENTS_PATH}`

export const makeArticleIdCommentReplyApiPath = (articleId, commentId) =>
  `${makeArticleIdCommentsApiPath(articleId)}/${commentId}`

export const makeArticleImageIdApiPath = (articleId, imageId) =>
  `${makeArticleIdApiPath(articleId)}/${IMAGES}/${imageId}`

export const makeEditorImageApiPath = () => `${API_ROOT}${IMAGES}`

export const makeWikiApiPath = () => `${WIKI_API}/api.php`
export const makeWikiApiPagePath = (page) =>
  `${makeWikiApiPath()}?action=parse&page=${page}&format=json&origin=${origin}`
export const makeWikiApiSearchTextPath = (query) =>
  `${makeWikiApiPath()}?action=query&list=search&srwhat=text&srsearch=${query}&srnamespace=${wikiNamespaces}&format=json&origin=${origin}`
export const makeWikiApiSearchTitlePath = (query) =>
  `${makeWikiApiPath()}?action=query&list=search&srwhat=title&srsearch=${query}&srnamespace=${wikiNamespaces}&format=json&origin=${origin}`
export const makeWikiApiMenu = () =>
  `${makeWikiApiPath()}?action=parse&page=MediaWiki:Sidebar&prop=wikitext&format=json&origin=${origin}`
export const makeWikiApiSavePagePath = (page, revId, timestamp, title) =>
  `${makeWikiApiPath()}?action=edit${page ? `&pageid=${page}` : ''}${title ? `&title=${title}` : ''}${
    revId ? `&baserevid=${revId}` : ''
  }&basetimestamp=${timestamp}&
  starttimestamp=${timestamp}&origin=${origin}&format=json`
export const makeWikiTokenPath = () => `${makeWikiApiPath()}?action=query&meta=tokens&origin=${origin}&format=json`
export const makeMediaWikiPath = (id) => `${WIKI_API}/index.php${id ? `?title=${id}` : ''}`

export const makeContentLatestLikesApiPath = (type, id) => `${API_ROOT}/${type}/${id}${LIKES_PATH}`

export const makeContestInvitationUserIdApiPath = (contestId, userId) =>
  `${makeContestApiPath(contestId)}${JUDGES_PATH}/${userId}`

export const makeCollectionsApiPath = () => `${API_ROOT}${COLLECTIONS_PATH}`

export const makeCollectionApiPath = (id) => `${makeCollectionsApiPath()}/${id}`

export const makeCollectionBookmarkApiPath = (id) => `${makeCollectionApiPath(id)}/${BOOKMARK_PATH}`

export const CONTENT_TYPES = {
  TYPE_ARTICLE: 'News',
  TYPE_CONTEST: 'Contest',
  TYPE_COMMUNITY_FAVORITE: 'Community',
}

export const COMMUNITY_FAVORITE_TITLE = 'Community Favorites'

export const SERVER_ERROR_CODE_NAMES = {
  LIVE_LOGIN_REQUIRED: 'LIVE_LOGIN_REQUIRED',
}

export const SOCKET_EVENTS = {
  BOOKMARK_STARTED: 'BOOKMARK_STARTED',
  BOOKMARK_FINISHED: 'BOOKMARK_FINISHED',
}

// TODO DEPRECATED API routes should be restructured
export default Object.freeze({
  ACTIVITIES: '/activities',
  ANNOUNCEMENTS: '/announcements',
  AUTH: '/api/login',
  AUTH_EMAIL: '/api/login/email',
  AUTH_REFRESH: '/api/login/refresh',
  COLLECTIONS: '/api/collections',
  COMMENTS: '/comments',
  CONTESTS: '/api/contests',
  CREATORS: '/api/preset/highlighted_creators',
  DOWNLOAD: '/download',
  EXTERNALS: '/externals',
  FAQ: '/faq',
  FAVORITES: '/favorites',
  FOLLOW: '/follow',
  FOLLOWERS: '/followers',
  GAMES: '/api/games',
  IGNORE: '/ignore',
  IMAGES: '/images',
  INVITE: '/invite',
  JUDGES: '/judges',
  LIKES: '/likes',
  LOGIN: '/api/login',
  LOGOUT: '/api/logout',
  MAPS: '/api/maps',
  MERCHANDISE: '/merchandise',
  NEW_PASSWORD: '/api/login/restore',
  PROFILES: '/profiles',
  REGISTER: '/api/register',
  REMAKES: '/api/remakes',
  REPORT: '/report',
  RESET_PASSWORD: '/api/login/forgot',
  SEARCH: '/api/search',
  STATISTICS: '/stats',
  SUBMIT_MAP: '/submit_new_map',
  SUPERADMIN: '/api/superadmin/tokens',
  USER: 'user',
  IMPORT: '/api/import',
  USERS: '/api/users',
  WEAPON_TYPES: '/api/weapons/types',
  WEAPONS: '/api/weapons',
  TYPE_BLOG_ARTICLE: 'News',
  TYPE_CONTESTS: 'Contest',
  COMMUNITY_FAVORITES: '/community_favorites',
  OAUTH2_CODE: '/api/oauth2/code',
})
